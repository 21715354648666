<template>
  <div class="">

    <!-- Popups-->
    <div class="mypopup position-fixed top-50 start-50 translate-middle">
      <div class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
        <div class="toast-header">
          <strong class="me-auto" style="color: coral;">Fehler</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <div class="mypopup position-fixed top-50 start-50 translate-middle">
      <div class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
        <div class="toast-header">
          <strong class="me-auto" style="color: lightgreen;">Erfolgreich übermittelt</strong>
          <router-link to="/Formulare">
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </router-link>
        </div>
        <div class="toast-body">
          Falls Sie noch Änderungen vornehmen möchten, können Sie dies jetzt tun und anschließend nochmals übermitteln.
        </div>
      </div>
    </div>
    <!-- Ende Popups-->

    <nav class="hide-at-print">
      <img src="/img/kienbaum.svg" class="logo-kienbaum-800 hide-at-print" alt="" width="190">
      <div :style="{ 'visibility': !$store.state.oneTimeAccessUUID ? 'visible' : 'hidden' }">
        <router-link to="/">Zurück zum Login-Bereich</router-link> |
        <router-link to="/Formulare">Zurück zu Ihren Formularen</router-link>
      </div>
    </nav>

    <div class="mt-4" v-if="formData !== null"
      :style="{ 'pointer-events': pointerEvents ? 'initial' : 'none', 'opacity': pointerEvents || $store.state.oneTimeAccessUUID ? '1.0' : '0.5' }">
      <img src="/img/kienbaum.svg" class="logo-kienbaum-small mb-4 mx-auto hide-at-print" alt="" width="190">

      <h2 class="mb-3">{{ formData.FORM }}</h2>

      <div class="parent break-all">
        <div class="grid-item item-buchung">
          <h5 class="mt-2">Buchung Nr. <i>{{ formData.NUMMER }}</i></h5>
          <div class="text-start ms-3 mt-3">
            <div class="mt-2"><span class="fw-bold">Kunde:</span> {{ formData.BUCHUNG.KUNDE }}</div>
            <div class="mt-2"><span class="fw-bold">Zeitraum:</span> {{ new
    Date(formData.BUCHUNG.ANREISE).toLocaleDateString('de',
      dateOptions)
}}
              - {{
    new Date(formData.BUCHUNG.ABREISE).toLocaleDateString('de', dateOptions)
}}
            </div>
            <div class="mt-2"><span class="fw-bold">Teilnehmerzahl:</span> {{ formData.BUCHUNG.ANZAHL }}</div>
          </div>
        </div>
        <div class="grid-item item-rechnung fw-bold">
          <h5 class="mt-2">Rechnungsanschrift</h5>
          <div class="grid-rechnung text-start mt-2 mx-3 mb-2">
            <div class="grid-rechnung-item-firma-header w-100"> <span class="fw-bold">Verein / Firma:</span> </div>
            <div class="grid-rechnung-item-firma ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.RECHNUNG.VEREIN" />
            </div>
            <div class="grid-rechnung-item-name-header w-100"> <span class="fw-bold">Name:</span> </div>
            <div class="grid-rechnung-item-name ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.RECHNUNG.NAME" />
            </div>
            <div class="grid-rechnung-item-strasse-header w-100"> <span class="fw-bold">Straße:</span></div>
            <div class="grid-rechnung-item-strasse ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.RECHNUNG.STRASSE" />
            </div>
            <div class="grid-rechnung-item-plz-header w-100"> <span class="fw-bold">Postleitzahl:</span> </div>
            <div class="grid-rechnung-item-plz ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.RECHNUNG.PLZ" />
            </div>
            <div class="grid-rechnung-item-ort-header w-100"><span class="fw-bold">Ort:</span> </div>
            <div class="grid-rechnung-item-ort ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.RECHNUNG.ORT" />
            </div>
          </div>
        </div>


        <div class="grid-item item-unterbringung">
          <h5 class="mt-2">Unterbringung</h5>
          Gebäude; Nummer
        </div>
        <div class="grid-item item-personalien">
          <h5 class="mt-2">Personalien</h5>
        </div>
        <div class="grid-item item-aufenthalt">
          <h5 class="mt-2">Aufenthalt</h5>
        </div>
        <div class="grid-item item-bemerkung">
          <h5 class="mt-2">Bemerkung</h5>
        </div>


        <div class="grid-item item-fullname pt-2">Name, Vorname </div>
        <div class="grid-item item-geburtsdatum pt-2">Geburtsdatum </div>
        <div class="grid-item item-funktion pt-2">Funktion </div>
        <div class="grid-item item-kaderstatus pt-2">Kaderstatus </div>
        <div class="grid-item item-anreise pt-2">Anreise </div>
        <div class="grid-item item-erste-mz pt-2">Erste<br />Mahlzeit<br />
          <div class="hide-at-print" v-if="!$store.state.oneTimeAccessUUID">
            <hr>
            Setze alle<br />
            Zeilen auf
            <select class="w-100" v-model="anEssenAllRows">
              <option v-for="item in $store.state.essenOptions" :value="item">{{ item }}</option>
            </select>
          </div>
        </div>
        <div class="grid-item item-abreise pt-2">Abreise </div>
        <div class="grid-item item-letzte-mz pt-2">Letzte<br />Mahlzeit<br />
          <div class="hide-at-print" v-if="!$store.state.oneTimeAccessUUID">
            <hr>
            Setze alle<br />
            Zeilen auf
            <select class=" w-100" v-model="abEssenAllRows">
              <option v-for="item in $store.state.essenOptions" :value="item">{{ item }}</option>
            </select>
          </div>
        </div>
      </div>
      <div v-for="item in formData.ZIMMER" class="zimmer-grid break-all">
        <div class="div1">{{ item.BESCHR }} &nbsp; {{ item.ZIMMER_KEY }}
        </div>

        <input class="div2" type="text" v-model="item.NAME" />
        <input class="div3" type="date" v-model="item.GEBDATE" />

        <select class="div4" v-model="item.FUNKTION">
          <option v-for="item in $store.state.funktionOptions" :value="item">{{ item }}</option>
        </select>
        <select class="div5" v-model="item.KADER">
          <option v-for="item in $store.state.kaderOptions" :value="item">{{ item }}</option>
        </select>

        <input class="div6" type="date" v-model="item.ANREISE" />
        <select class="div7" v-model="item.AN_ESSEN">
          <option v-for="item in $store.state.essenOptions" :value="item">{{ item }}</option>
        </select>
        <input class="div8" type="date" v-model="item.ABREISE" />
        <select class="div9" v-model="item.AB_ESSEN">
          <option v-for="item in $store.state.essenOptions" :value="item">{{ item }}</option>
        </select>
        <textarea class="div10" rows="1" v-model="item.BEMERKUNG"></textarea>
      </div>

      <div class="hide-at-print" v-if="!$store.state.oneTimeAccessUUID">
        <div class="mt-4">
          <button type="button" class="btn btn-secondary" @click="trySaveForm(true)">Zwischenspeichern</button>
          <div class="mt-2" :style="{
  'visibility': hasSaved ? 'visible' : 'hidden'
}"><small>Lokal gespeichert!</small></div>
        </div>

        <div>
          <div class="mt-3">
            <button type="button" class="btn btn-primary" @click="sendForm()">Zimmerliste einreichen</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { registerAndGetPopups, checkOnlineStatus, getRealDateNow, getServerURL } from '@/helper';
import { set, get } from 'idb-keyval';
import "@/assets/grid-rechnung.css";

export default {
  name: 'ZimmerlisteFormView',
  async mounted() {
    //Bei F5 reload wieder zu root, weil Passwort etc ja fehlt
    if (this.$store.state.bookingNumber === "" && !this.$store.state.oneTimeAccessUUID) {
      this.$router.push("/");
      return;
    }
    if (this.$store.state.oneTimeAccessUUID) {
      this.pointerEvents = false;
    }
    // die verschiedenen Popups registrieren
    this.toastList = registerAndGetPopups();

    const data = await get('lastEditedZimmerlisteForm');
    if (data) {
      this.formData = JSON.parse(data);
    } else {
      this.$router.push("Formulare");
    }
  },
  watch: {
    anEssenAllRows(newValue) {
      for (let index = 0; index < this.formData.ZIMMER.length; index++) {
        this.formData.ZIMMER[index].AN_ESSEN = newValue;
      }
    },
    abEssenAllRows(newValue) {
      for (let index = 0; index < this.formData.ZIMMER.length; index++) {
        this.formData.ZIMMER[index].AB_ESSEN = newValue;
      }
    }
  },
  methods: {
    async trySaveForm(showHint) {

      if (await checkOnlineStatus()) {

        const data = { bookingNumber: this.$store.state.bookingNumber };

        if (this.$store.state.institution) {
          data.password = this.formData.PASSWORT;
          data.isHashedPassword = true;
        } else {
          data.password = this.$store.state.password;
        }

        const response = await fetch(getServerURL() + '/api/forms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          return true; // ist ok hier
        }

        const serverFormlist = await response.json();
        const serverForm = serverFormlist.find(x => x.ID === this.formData.ID);

        if (!serverForm) {
          this.errorMessage = "Achtung - das Formular wurde zwischenzeitlich vom Administrator entfernt."
          this.toastList[0].show();

          setTimeout(() => {
            this.$router.push("/");
          }, 3000);
          return false;
        } else {
          if (serverForm.ZUGANG !== this.formData.ZUGANG) {
            this.errorMessage = "Achtung - das Formular wurde zwischenzeitlich vom Administrator geändert. Daten werden zurückgesetzt. Bitte erneut ausfüllen."
            this.toastList[0].show();

            this.formData = serverForm;

            this.saveForm();
            return false;
          }
        }
      }

      // Setze aktuelles Datum
      this.formData.USER_DATUM = getRealDateNow();

      if (showHint) {
        this.hasSaved = this.saveForm();

        if (this.hasSaved) {
          setTimeout(() => {
            this.hasSaved = false;
          }, 2000);
          return true;
        } else {
          return false;
        }
      } else {
        return this.saveForm();
      }
    },
    async saveForm() {
      try {

        await set("lastEditedZimmerlisteForm", JSON.stringify(this.formData));

        // Element auch aktualisieren in lokaler Liste
        const data = await get('localFormlist');
        let localFormlist = data ? JSON.parse(data) : [];

        localFormlist = localFormlist.map(x => {
          return this.formData.ID === x.ID ? this.formData : x;
        });

        await set('localFormlist', JSON.stringify(localFormlist));
        return true;
      } catch (error) {
        console.log('Saving error!', err);
        this.errorMessage = "Fehler beim Speichern des gewählten Formulars."
        this.toastList[0].show();
        return false;
      }
    },
    async sendForm() {

      if (!(await this.trySaveForm(false))) {
        return;
      }

      // // Validieren
      // if (!this.formData.RECHNUNG.VEREIN) {
      //   this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Rechnungsanschrift -> Verein / Firma"
      //   this.toastList[0].show();
      //   return;
      // }
      // if (!this.formData.RECHNUNG.NAME) {
      //   this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Rechnungsanschrift -> Name"
      //   this.toastList[0].show();
      //   return;
      // }
      // if (!this.formData.RECHNUNG.STRASSE) {
      //   this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Rechnungsanschrift -> Straße"
      //   this.toastList[0].show();
      //   return;
      // }
      // if (!this.formData.RECHNUNG.PLZ) {
      //   this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Rechnungsanschrift -> PLZ"
      //   this.toastList[0].show();
      //   return;
      // }
      // if (!this.formData.RECHNUNG.ORT) {
      //   this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Rechnungsanschrift -> Ort"
      //   this.toastList[0].show();
      //   return;
      // }

      if (!(await checkOnlineStatus())) {
        this.errorMessage = "Das Formular kann erst übermittelt werden, wenn Sie wieder online sind. Ihre Eingaben bleiben bestehen, auch wenn der Browser geschlossen wird."
        this.toastList[0].show();
        return;
      }

      const data = { bookingNumber: this.$store.state.bookingNumber, formData: this.formData };

      if (this.$store.state.institution) {
        data.password = this.formData.PASSWORT;
        data.isHashedPassword = true;
      } else {
        data.password = this.$store.state.password;
      }

      fetch(getServerURL() + '/api/forms/' + this.formData.ID, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(async response => {
          if (!response.ok) {
            this.errorMessage = "Das Absenden war nicht möglich."
            this.toastList[0].show();
          } else {
            this.pointerEvents = false;
            this.toastList[1].show();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          this.errorMessage = "Das Absenden war nicht möglich."
          this.toastList[0].show();
        });
    },
  },
  data() {
    return {
      toastList: [],
      pointerEvents: true,
      errorMessage: "",
      dateOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },

      formData: null,
      hasSaved: false,

      anEssenAllRows: "",
      abEssenAllRows: ""
    }
  },
}
</script>
<style scoped>
@media (max-width: 799px) {
  .logo-kienbaum-800 {
    display: none;
  }

  .logo-kienbaum-small {
    display: block;
  }
}

@media (min-width: 800px) {
  .logo-kienbaum-800 {
    display: block;
    position: absolute;
    right: 2rem;
    top: 3rem;
  }

  .logo-kienbaum-small {
    display: none;
  }
}

@media print {
  @page {
    size: A3;
    /* interessanter Workaround im das Fit-to-page auf A4 anzustoßen: https: //stackoverflow.com/questions/28757370/scale-html-table-before-printing-using-css */
  }

  .hide-at-print {
    display: none;
  }
}

input,
textarea,
select {
  background-color: #e9e9ed;
  padding-left: 0.5rem;
}

select {
  padding-left: 0.1rem;
}

h2 {
  text-decoration: underline;
}

h5 {
  font-weight: bold;
}

.parent {
  min-width: 1500px;
  display: grid;
  grid-template-columns: repeat(20, minmax(0, 1fr));
  /* minmax(0, 1fr) anstelle von 1fr siehe https: //stackoverflow.com/questions/60193734/grid-template-columns-in-chrome-80-inconsistently-computed */
  /*grid-template-rows: 3fr repeat(2, 1fr);*/
  /* ohne Angabe von grid-template-rows scheint es einfach auto-height zu sein */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid black;
}

.grid-item {
  border: 1px solid black;
}

.item-buchung {
  grid-area: 1 / 1 / 2 / 11;
}

.item-rechnung {
  grid-area: 1 / 11 / 2 / 21;
}

.item-unterbringung {
  grid-area: 2 / 1 / 4 / 4;
}

.item-personalien {
  grid-area: 2 / 4 / 3 / 11;
}

.item-aufenthalt {
  grid-area: 2 / 11 / 3 / 17;
}

.item-bemerkung {
  grid-area: 2 / 17 / 4 / 21;
}

.item-fullname {
  grid-area: 3 / 4 / 4 / 7;
}

.item-geburtsdatum {
  grid-area: 3 / 7 / 4 / 9;
}

.item-funktion {
  grid-area: 3 / 9 / 4 / 10;
}

.item-kaderstatus {
  grid-area: 3 / 10 / 4 / 11;
}

.item-anreise {
  grid-area: 3 / 11 / 4 / 13;
}

.item-erste-mz {
  grid-area: 3 / 13 / 4 / 14;
}

.item-abreise {
  grid-area: 3 / 14 / 4 / 16;
}

.item-letzte-mz {
  grid-area: 3 / 16 / 4 / 17;
}

.zimmer-grid {
  min-width: 1500px;
  display: grid;
  grid-template-columns: repeat(20, minmax(0, 1fr));
  /* minmax(0, 1fr) anstelle von 1fr siehe https: //stackoverflow.com/questions/60193734/grid-template-columns-in-chrome-80-inconsistently-computed */
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid black;
}

.div1 {
  grid-area: 1 / 1 / 2 / 4;
  place-self: center;
}

.div2 {
  grid-area: 1 / 4 / 2 / 7;
}

.div3 {
  grid-area: 1 / 7 / 2 / 9;
}

.div4 {
  grid-area: 1 / 9 / 2 / 10;
}

.div5 {
  grid-area: 1 / 10 / 2 / 11;
}

.div6 {
  grid-area: 1 / 11 / 2 / 13;
}

.div7 {
  grid-area: 1 / 13 / 2 / 14;
}

.div8 {
  grid-area: 1 / 14 / 2 / 16;
}

.div9 {
  grid-area: 1 / 16 / 2 / 17;
}

.div10 {
  grid-area: 1 / 17 / 2 / 21;
  min-height: 2rem;
}
</style>
