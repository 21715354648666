<template>
  <div class="">

    <!-- Popups-->
    <div class="mypopup position-fixed top-50 start-50 translate-middle">
      <div class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
        <div class="toast-header">
          <strong class="me-auto" style="color: coral;">Fehler</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <div class="mypopup position-fixed top-50 start-50 translate-middle">
      <div class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
        <div class="toast-header">
          <strong class="me-auto">Achtung: Ein aktualisiertes Formular wurde ersetzt</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ message }} <br>
          Bestehende Daten müssen eventuell erneut eingegeben werden.
        </div>
      </div>
    </div>
    <!-- Ende Popups-->

    <nav>
      <img src="/img/kienbaum.svg" class="logo-kienbaum-800" alt="" width="190">
      <router-link to="/">Zurück zum Login-Bereich</router-link>
    </nav>

    <div class="mt-5">
      <img src="/img/kienbaum.svg" class="logo-kienbaum-small mb-4 mx-auto" alt="" width="190">

      <div v-if="this.$store.state.institution">
        <div class="mb-5 m-auto mt-4" style="width: 300px;">
          <select class="form-select form-select-lg mb-3" @change="setFilteredFormList()"
            v-model="$store.state.selectedBookingNumber">
            <option value="">Buchungsnummer wählen</option>
            <option :value="bookingNumber" v-for="bookingNumber in $store.state.institutionBookingNumbers">{{
              bookingNumber
            }}
            </option>
          </select>

        </div>
      </div>

      <div v-show="this.$store.state.selectedBookingNumber">
        <h2 class="mb-4">Ihre Formulare</h2>
        <div v-if="formsOnlyFormList().length === 0" class="small">-- Noch keine Formulare vorhanden --</div>
        <ul style="list-style: none; padding-left: 0;">
          <li v-for="(item, index) in formsOnlyFormList()">
            <div class="container">
              <div class="row" v-if="index === 0">
                <div class="col fw-bold p-2">
                  Formular
                </div>
                <div class="col fw-bold p-2">
                  Angelegt durch
                </div>
                <div class="col fw-bold p-2">
                  Zuletzt bearbeitet am
                </div>

              </div>
              <div class="row">
                <div class="col border-top border-bottom p-2 mb-1">
                  <a href="#" @click.prevent="goToForm(item)" class="">
                    {{ item.FORM }}
                  </a>
                </div>
                <div class="col border-top border-bottom p-2 mb-1">
                  {{ item.BUCHER }} ({{ new Date(item.ZUGANG).toLocaleDateString('de', dateOptions) }})
                </div>
                <div class="col border-top border-bottom p-2 mb-1">
                  <span v-if="item.USER_DATUM !== null">{{ new
                    Date(item.USER_DATUM).toLocaleDateString('de',
                      dateOptions)
                  }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <h2 class="mt-5 mb-4">Hochgeladene Dateien</h2>
        <div v-if="uploadOnlyFormList().length === 0" class="small">-- Keine Dateien vorhanden --</div>
        <ul style="list-style: none; padding-left: 0;">
          <li v-for="(item, index) in uploadOnlyFormList()">
            <div class="container">
              <div class="row" v-if="index === 0">
                <div class="col fw-bold p-2">
                  Datei
                </div>
                <div class="col fw-bold p-2">
                  Hochgeladen durch
                </div>
                <div class="col fw-bold p-2">
                  Größe
                </div>
              </div>
              <div class="row">
                <div class="col border-top border-bottom p-2 mb-1">
                  <a href="#" @click.prevent="getUpload(item)" class="">
                    {{ item.DATEI.originalname }}
                  </a>
                </div>
                <div class="col border-top border-bottom p-2 mb-1">
                  {{ item.BUCHER }} ({{ new Date(item.ZUGANG).toLocaleDateString('de', dateOptions) }})
                </div>
                <div class="col border-top border-bottom p-2 mb-1">
                  <span>{{ humanFileSize(item.DATEI.size)
                  }}</span>
                  <!-- <a href="#" @click.prevent="deleteFile(item)" class="">
                  &times;
                </a> -->
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div v-show="checkOnlineStatus()">
          <form id="upload-widget" enctype="multipart/form-data" class="dropzone mt-5">
            <div class="fallback">
              <input name="file" type="file" />
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { getServerURL, registerAndGetPopups, humanFileSize, checkOnlineStatus } from '@/helper';
import { set, get, del } from 'idb-keyval';
import Dropzone from "dropzone";

export default {
  name: 'FormListView',
  async mounted() {
    const isOnline = await this.checkOnlineStatus();
    //Bei F5 reload wieder zu root, weil Passwort etc ja fehlt
    if (this.$store.state.bookingNumber === "" && this.$store.state.institution === "" && isOnline) {
      this.$router.push("/");
      return;
    }
    // die verschiedenen Popups registrieren
    this.toastList = registerAndGetPopups();

    // lade gerade empfangene Daten aus dem Cache
    const dataServer = await get('serverFormlist');
    let serverFormlist = dataServer ? JSON.parse(dataServer) : [];
    const dataLocal = await get('localFormlist');
    let localFormlist = dataLocal ? JSON.parse(dataLocal) : [];

    if (isOnline && serverFormlist && serverFormlist.length > 0) {
      if (!localFormlist || localFormlist.length === 0) {
        await this.saveAndSetLocalFormList(serverFormlist);
        await del("serverFormlist");
      } else {
        // überprüfe ob Änderungen vorgenommen wurden
        let newLocalFormList = [];

        // Alle vom Server die dort neu sind lokal hinzufügen
        // Bestehende ignorieren, es sei denn sie wurden aktualisiert auf dem Server -> nimm diese und zeige Nachricht
        for (let index = 0; index < serverFormlist.length; index++) {
          const serverForm = serverFormlist[index];
          const localForm = localFormlist.find(x => x.ID === serverForm.ID);

          if (!localForm) {
            newLocalFormList.push(serverForm);
          } else {
            if (serverForm.ZUGANG !== localForm.ZUGANG) {
              newLocalFormList.push(serverForm);
              this.message = "Buchungsnummer " + serverForm.NUMMER + ": " + serverForm.FORM;
              this.toastList[1].show();
            } else {
              newLocalFormList.push(localForm);
            }
          }
        }

        await this.saveAndSetLocalFormList(newLocalFormList);
        await del("serverFormlist");
      }
    } else {
      this.formlist = localFormlist;
    }

    if (!isOnline) {
      let bookingNumbers = [...new Set(this.formlist.map(x => x.NUMMER))];
      bookingNumbers.sort();
      if (bookingNumbers.length > 1) {
        this.$store.state.institution = true;
        this.$store.state.institutionBookingNumbers = bookingNumbers;
      } else {
        this.$store.state.bookingNumber = this.$store.state.selectedBookingNumber = bookingNumbers[0];
      }
    }

    if (this.$store.state.institution) {
      // falls man zurückgeht bzw geleitet wird auf die Komponente
      if (this.$store.state.selectedBookingNumber) {
        this.setFilteredFormList();
      } else {
        this.filteredFormlist = [];
      }
    } else {
      this.filteredFormlist = this.formlist;
    }

    const thisComponent = this;
    const store = this.$store;

    let myDropzone = new Dropzone("#upload-widget", {
      url: getServerURL() + "/api/upload",
      maxFilesize: 10, // MB
      maxFiles: null,
      dictDefaultMessage: "Drag & Drop oder hier klicken, um Inhalte hochzuladen",
      dictFallbackMessage: "Ihr Browser unterstützt kein Drag & Drop.",
      dictFallbackText: null,
      dictFileTooBig: "Die Datei ist zu groß ({{filesize}} MB). Maximal erlaubte Größe: {{maxFilesize}} MB.",
      dictInvalidFileType: "Das Hochladen dieses Dateityps wird nicht unterstützt.",
      dictResponseError: "Der Server antwortete mit dem Code {{statusCode}}.",
      dictCancelUpload: "Abbrechen",
      dictUploadCanceled: "Hochladen abgebrochen",
      dictCancelUploadConfirmation: "Wollen Sie das Hochladen wirklich abbrechen?",
      dictRemoveFile: "Eintrag entfernen",
      // headers: {
      //   'x-csrf-token': document.querySelectorAll('meta[name=csrf-token]')[0].getAttributeNode('content').value,
      // },
      addRemoveLinks: false,
      acceptedFiles: '.pdf',
      accept: function (file, done) {
        if (file.size === 0) {
          done("Leere Dateien können nicht hochgeladen werden.");
        }
        else {
          done();
        }
      },
      init: function () {
        this.on("sending", function (file, xhr, formData) {

          if (store.state.institution) {
            formData.append('bookingNumber', store.state.bookingNumber);
            formData.append('password', thisComponent.filteredFormlist[0].PASSWORT);
            formData.append('isHashedPassword', 'true');
            formData.append('bucher', 'Kunde');
          } else {
            formData.append('bookingNumber', store.state.bookingNumber);
            formData.append('password', store.state.password);
            formData.append('bucher', 'Kunde');
          }
        });
        this.on('success', async function (file, resp) {
          thisComponent.formlist.push(resp);
          await thisComponent.saveAndSetLocalFormList(thisComponent.formlist);
          thisComponent.setFilteredFormList();
          setTimeout(() => {
            myDropzone.removeFile(file);
          }, 3000);
        });
      },
    });
  },
  methods: {
    setFilteredFormList() {
      this.filteredFormlist = this.formlist.filter(x => x.NUMMER === this.$store.state.selectedBookingNumber);
      this.$store.state.bookingNumber = this.$store.state.selectedBookingNumber;
    },
    async saveAndSetLocalFormList(list) {
      await set('localFormlist', JSON.stringify(list))
        .then(() => {
          this.formlist = list;
        })
        .catch((err) => {
          console.log('Saving error!', err);
          this.errorMessage = "Fehler beim Speichern der lokalen Formulare."
          this.toastList[0].show();
        });
    },
    formsOnlyFormList() {
      return this.filteredFormlist.filter(x => x.FORM !== 'Upload');
    },
    uploadOnlyFormList() {
      return this.filteredFormlist.filter(x => x.FORM === 'Upload');
    },
    humanFileSize(size) {
      return humanFileSize(size, true);
    },
    async checkOnlineStatus() {
      return await checkOnlineStatus();
    },
    goToForm(data) {
      switch (data.FORM) {
        case "Zimmerliste":
          set('lastEditedZimmerlisteForm', JSON.stringify(data))
            .then(() => {
              this.$router.push("Zimmerliste");
            })
            .catch((err) => {
              console.log('Saving error!', err);
              this.errorMessage = "Fehler beim Speichern des gewählten Formulars."
              this.toastList[0].show();
            });
          break;
        case "Vertrag":
          set('lastEditedVertragForm', JSON.stringify(data))
            .then(() => {
              this.$router.push("Vertrag");
            })
            .catch((err) => {
              console.log('Saving error!', err);
              this.errorMessage = "Fehler beim Speichern des gewählten Formulars."
              this.toastList[0].show();
            });
          break;
        case "Küchenliste":
          set('lastEditedKuechenlisteForm', JSON.stringify(data))
            .then(() => {
              this.$router.push("Kuechenliste");
            })
            .catch((err) => {
              console.log('Saving error!', err);
              this.errorMessage = "Fehler beim Speichern des gewählten Formulars."
              this.toastList[0].show();
            });
          break;
        default:
          this.errorMessage = "Formulartyp ist nicht bekannt."
          this.toastList[0].show();
          break;
      }
    },
    async getUpload(item) {
      const data = { bookingNumber: this.$store.state.bookingNumber };

      if (this.$store.state.institution) {
        data.password = item.PASSWORT;
        data.isHashedPassword = true;
      } else {
        data.password = this.$store.state.password;
      }

      await fetch(getServerURL() + '/api/forms/' + item.ID + "/file", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => {
          if (response.ok) {
            return response.blob();
          } else
            return Promise.reject(response.status); // erst so kommen wir zum catch
        })
        .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = item.DATEI.originalname;
          a.style.display = 'none';
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
        })
        .catch((error) => {
          console.error('Error:', error);
          this.errorMessage = "Das Abrufen der Datei war leider nicht möglich."
          this.toastList[0].show();
        });
    },
    // async deleteFile(item) {
    //     const data = { bookingNumber: this.$store.state.bookingNumber };

    //     if(this.$store.state.institution) {
    //   data.password = item.PASSWORT;
    //   data.isHashedPassword = true;
    // } else {
    //   data.password = this.$store.state.password;
    // }
    //   await fetch(getServerURL() + '/api/forms/' + item.ID + "/remove", {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(data),
    //   })
    //     .catch((error) => {
    //       console.error('Error:', error);
    //       this.errorMessage = "Das Löschen der Datei war leider nicht möglich."
    //       this.toastList[0].show();
    //     });
    // }
  },
  data() {
    return {
      toastList: [],
      errorMessage: "",
      message: "",
      dateOptions: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },

      formlist: [],
      filteredFormlist: [],
    }
  },
}
</script>
<style scoped>
@media (max-width: 799px) {
  .logo-kienbaum-800 {
    display: none;
  }

  .logo-kienbaum-small {
    display: block;
  }
}

@media (min-width: 800px) {
  .logo-kienbaum-800 {
    display: block;
    position: absolute;
    right: 2rem;
    top: 3rem;
  }

  .logo-kienbaum-small {
    display: none;
  }

  .dropzone {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

h2 {
  text-decoration: underline;
}

.dropzone {
  background:
    linear-gradient(90deg, white 21px, transparent 1%) center,
    linear-gradient(white 21px, transparent 1%) center,
    #989898;
  background-size: 22px 22px;
  opacity: 0.8;
}
</style>
