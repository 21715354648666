import FormListView from "@/views/FormListView.vue";
import ZimmerlisteFormView from "@/views/ZimmerlisteFormView.vue";
import VertragFormView from "@/views/VertragFormView.vue";
import KuechenlisteFormView from "@/views/KuechenlisteFormView.vue";
import { createRouter, createWebHashHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";

const routes = [
    {
        path: "/",
        name: "Login",
        component: LoginView,
    },
    {
        path: "/Formulare",
        name: "Formulare",
        component: FormListView,
    },
    {
        path: "/Zimmerliste",
        name: "Zimmerliste",
        component: ZimmerlisteFormView,
    },
    {
        path: "/Vertrag",
        name: "Vertrag",
        component: VertragFormView,
    },
    {
        path: "/Kuechenliste",
        name: "Kuechenliste",
        component: KuechenlisteFormView,
    },
    // {
    //     path: "/about",
    //     name: "about",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: function () {
    //         return import(
    //             /* webpackChunkName: "about" */ "../views/AboutView.vue"
    //         );
    //     },
    // },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
