import Toast from "bootstrap/js/dist/toast"; // fürs tree-shaking

export function getServerURL() {
    return process.env.VUE_APP_API_SERVER_URL;
}

async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 1100 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
        ...options,
        signal: controller.signal,
    });
    clearTimeout(id);
    return response;
}

export async function checkOnlineStatus() {
    try {
        const online = await fetchWithTimeout(
            getServerURL() + "/api/online-status"
        );
        return online.status >= 200 && online.status < 300; // either true or false
    } catch (err) {
        return false; // definitely offline
    }
}

export function registerAndGetPopups() {
    var toastElList = Array.from(document.querySelectorAll(".toast"));
    return toastElList.map(function (toastEl) {
        return new Toast(toastEl, {});
    });
}

export function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
    );
    return uuid;
}

/// tatsächliches Datum inkl. offset (wegen daylight savings time) im Format YYYY-MM-DD
export function getRealDateNow() {
    let now = new Date();
    const offset = now.getTimezoneOffset();
    return new Date(now.getTime() - offset * 60 * 1000)
        .toISOString()
        .split("T")[0];
}

export function isNullOrWhitespace(str) {
    return str === null || /^\s*$/.test(str);
}

export function isInvalidNumberInput(val) {
    return isNullOrWhitespace(val) || Number(val) < 0 || Number(val) > 999; // wegen copy-paste und wegen negativen Zahlen allg.
}

export function humanFileSize(bytes, si) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + " Bytes";
    }
    var units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(0) + " " + units[u];
}
