/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      //console.log('New content is available; please refresh.')
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!registration) {
        console.log("no registration")
        return;
      }

      if(!registration.waiting){
        console.log("not waiting")
        return;
      }

      // Send message to SW to skip the waiting and activate the new SW
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      console.log("replaced sw");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
