import { createStore } from "vuex";

export default createStore({
    state: {
        bookingNumberOrInstitution: "",
        institution: "",
        institutionBookingNumbers: [],
        bookingNumber: "",
        selectedBookingNumber: "",
        password: "",
        oneTimeAccessUUID: "",
        essenOptions: [],
        physioOptions: [],
        sonderVerpflegungOptions: [],
        funktionOptions: [],
        kaderOptions: [],
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {},
});
