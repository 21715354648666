<template>
  <div>
    <!-- Popups-->
    <div class="mypopup position-fixed top-50 start-50 translate-middle">
      <div class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
        <div class="toast-header">
          <strong class="me-auto" style="color: coral;">Fehler</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <div class="mypopup position-fixed top-50 start-50 translate-middle">
      <div class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
        <div class="toast-header">
          <strong class="me-auto">Aktion erforderlich</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Sie müssen zuerst online gehen und sich einloggen.
        </div>
      </div>
    </div>
    <!-- Ende Popups-->

    <div v-show="showContent" style="display: none;">
      <img src="/img/kienbaum.svg" class="me-2 mt-2" alt="" width="500" style="max-width: 100%;">

      <h2 class="mb-5 mt-5">Willkommen beim Formularservice</h2>

      <div v-if="isOfflineAndHasLocalFormList">
        <div>Sie sind offline. Sie können aber Ihre abgerufenen Formulare bearbeiten.</div>
        <button type="button" class="btn btn-secondary mt-4" @click="$router.push('Formulare')">Zu Ihren
          Formularen</button>
      </div>
      <form v-else @submit.prevent="getServerForms()">
        <div class="mb-3 m-auto mt-4" style="width: 300px;">
          <label for="bookingNumberOrInstitutionInput" class="form-label">Buchungsnummer oder Institution</label>
          <input required type="text" v-model="$store.state.bookingNumberOrInstitution" class="form-control"
            id="bookingNumberOrInstitutionInput" placeholder="">
        </div>
        <div class="mb-3 m-auto" style="width: 300px;">
          <label for="passwordInput" class="form-label">Passwort</label>
          <input required type="password" v-model="$store.state.password" class="form-control" id="passwordInput">
        </div>
        <div class="mt-4">
          <button :disabled="$store.state.bookingNumberOrInstitution === '' || $store.state.password === ''" type="submit"
            class="btn btn-secondary">Formulare
            abrufen</button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { registerAndGetPopups, checkOnlineStatus, getServerURL } from '@/helper';
import { set, get } from 'idb-keyval';

export default {
  name: 'HomeView',
  async mounted() {
    // die verschiedenen Popups registrieren
    this.toastList = registerAndGetPopups();

    await this.getAndSetParams();

    const { onetimeaccessuuid } = this.$route.query
    if (onetimeaccessuuid) {
      await this.getServerFormsByOneTimeAccessUUID(onetimeaccessuuid);
      return;
    } else {
      this.showContent = true;
    }

    this.isOfflineAndHasLocalFormList = (!(await checkOnlineStatus()) && (await get('localFormlist')));
  },
  methods: {
    async getAndSetParams() {

      if (await checkOnlineStatus()) {
        let response = await fetch(getServerURL() + '/api/params');

        if (response.ok) {
          let params = await response.json();

          set('params', JSON.stringify(params))
            .then(() => {
              this.setParamsIntoStore(params);
            })
            .catch((err) => {
              console.log('Saving error!', err);
              this.errorMessage = "Fehler beim Speichern der empfangenen Parameter."
              this.toastList[0].show();
            });
        }
      } else {
        const data = await get('params').catch((err) => {
          console.log('Loading error!', err);
          this.errorMessage = "Fehler beim Laden der gespeicherten Parameter."
          this.toastList[0].show();
        });
        if (data) {
          let params = JSON.parse(data);
          this.setParamsIntoStore(params);
        } else {
          this.errorMessage = "Fehler beim Laden der gespeicherten Parameter."
          this.toastList[0].show();
        }
      }
    },
    setParamsIntoStore(params) {
      for (let index = 0; index < params.length; index++) {
        const element = params[index];
        switch (element.VARI) {
          case "Mahlzeiten":
            this.$store.state.essenOptions = this.parseParamsFromStringIntoArray(element.WERT);
            break;
          case "Physiotherapie":
            this.$store.state.physioOptions = this.parseParamsFromStringIntoArray(element.WERT);
            break;
          case "Sonderverpflegung":
            this.$store.state.sonderVerpflegungOptions = this.parseParamsFromStringIntoArray(element.WERT);
            break;
          case "Zimmerliste.Funktionen":
            this.$store.state.funktionOptions = this.parseParamsFromStringIntoArray(element.WERT);
            break;
          case "Zimmerliste.Kader":
            this.$store.state.kaderOptions = this.parseParamsFromStringIntoArray(element.WERT);
            break;
          default:
            this.errorMessage = `Unbekannter Parameter entdeckt. ${element.VARI}`;
            this.toastList[0].show();
            break;
        }
      }
    },
    parseParamsFromStringIntoArray(wert) {
      return wert.split(";");
    },
    async getServerForms() {
      // sollte nicht auftreten
      if (!(await checkOnlineStatus())) {
        this.toastList[1].show();
        return;
      }

      // Reset falls man ohne neuladen sich jetzt per Buchungsnummer einloggt und vorher als Institution drin war
      this.$store.state.institution = "";
      this.$store.state.institutionBookingNumbers = [];
      this.$store.state.selectedBookingNumber = "";

      // Institutionen beginnen nicht mit einer Zahl
      if (isNaN(parseInt(this.$store.state.bookingNumberOrInstitution[0]))) {
        this.$store.state.institution = this.$store.state.bookingNumberOrInstitution;
        const data = { institution: this.$store.state.institution, password: this.$store.state.password };

        await fetch(getServerURL() + '/api/institution', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(response => {
            if (response.ok) {
              return response.json()
            } else
              return Promise.reject(response.status); // erst so kommen wir zum catch
          }
          )
          .then(data => {
            let bookingNumbers = [...new Set(data.map(x => x.NUMMER))];
            bookingNumbers.sort();
            this.$store.state.institutionBookingNumbers = bookingNumbers;
            set('serverFormlist', JSON.stringify(data))
              .then(() => {
                this.$router.push("Formulare");
              })
              .catch((err) => {
                console.log('Saving error!', err);
                this.errorMessage = "Fehler beim Speichern der empfangenen Formulare."
                this.toastList[0].show();
              });
          })
          .catch((error) => {
            console.error('Error:', error);
            this.errorMessage = "Das Abrufen der Formulare war leider nicht möglich."
            this.toastList[0].show();
          });
      } else {
        this.$store.state.bookingNumber = this.$store.state.bookingNumberOrInstitution;
        this.$store.state.selectedBookingNumber = this.$store.state.bookingNumber;
        const data = { bookingNumber: this.$store.state.bookingNumber, password: this.$store.state.password };

        await fetch(getServerURL() + '/api/forms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(response => {
            if (response.ok) {
              return response.json()
            } else
              return Promise.reject(response.status); // erst so kommen wir zum catch
          }
          )
          .then(data => {
            set('serverFormlist', JSON.stringify(data))
              .then(() => {
                this.$router.push("Formulare");
              })
              .catch((err) => {
                console.log('Saving error!', err);
                this.errorMessage = "Fehler beim Speichern der empfangenen Formulare."
                this.toastList[0].show();
              });
          })
          .catch((error) => {
            console.error('Error:', error);
            this.errorMessage = "Das Abrufen der Formulare war leider nicht möglich."
            this.toastList[0].show();
          });
      }


    },
    async getServerFormsByOneTimeAccessUUID(oneTimeAccessUUID) {
      // sollte nicht auftreten
      if (!(await checkOnlineStatus())) {
        this.toastList[1].show();
        return;
      }

      this.$store.state.oneTimeAccessUUID = oneTimeAccessUUID;

      const data = { oneTimeAccessUUID };

      await fetch(getServerURL() + '/api/forms/onetime-access', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => {
          if (response.ok) {
            return response.json()
          } else
            return Promise.reject(response.status); // erst so kommen wir zum catch
        }
        )
        .then(data => {
          switch (data[0].FORM) {
            case "Zimmerliste":
              set('lastEditedZimmerlisteForm', JSON.stringify(data[0]))
                .then(() => {
                  this.$router.replace("Zimmerliste");
                })
                .catch((err) => {
                  console.log('Saving error!', err);
                  this.errorMessage = "Fehler beim Speichern des gewählten Formulars."
                  this.toastList[0].show();
                });
              break;
            case "Vertrag":
              set('lastEditedVertragForm', JSON.stringify(data[0]))
                .then(() => {
                  this.$router.replace("Vertrag");
                })
                .catch((err) => {
                  console.log('Saving error!', err);
                  this.errorMessage = "Fehler beim Speichern des gewählten Formulars."
                  this.toastList[0].show();
                });
              break;
            case "Küchenliste":
              set('lastEditedKuechenlisteForm', JSON.stringify(data[0]))
                .then(() => {
                  this.$router.replace("Kuechenliste");
                })
                .catch((err) => {
                  console.log('Saving error!', err);
                  this.errorMessage = "Fehler beim Speichern des gewählten Formulars."
                  this.toastList[0].show();
                });
              break;
            default:
              this.errorMessage = "Formulartyp ist nicht bekannt."
              this.toastList[0].show();
              break;
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          this.errorMessage = "Das Abrufen des Formulars war leider nicht möglich."
          this.toastList[0].show();
        });
    },
  },
  data() {
    return {
      toastList: [],
      errorMessage: "",
      isOfflineAndHasLocalFormList: false,
      showContent: false
    }
  },
}
</script>
<style scoped></style>
