<template>
  <div class="">

    <!-- Popups-->
    <div class="mypopup position-fixed top-50 start-50 translate-middle">
      <div class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
        <div class="toast-header">
          <strong class="me-auto" style="color: coral;">Fehler</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <div class="mypopup position-fixed top-50 start-50 translate-middle">
      <div class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
        <div class="toast-header">
          <strong class="me-auto" style="color: lightgreen;">Erfolgreich übermittelt</strong>
          <router-link to="/Formulare">
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </router-link>
        </div>
        <div class="toast-body">
          Falls Sie noch Änderungen vornehmen möchten, können Sie dies jetzt tun und anschließend nochmals übermitteln.
        </div>
      </div>
    </div>
    <!-- Ende Popups-->

    <nav class="hide-at-print">
      <img src="/img/kienbaum.svg" class="logo-kienbaum-800 hide-at-print" alt="" width="190">
      <div :style="{ 'visibility': !$store.state.oneTimeAccessUUID ? 'visible' : 'hidden' }">
        <router-link to="/">Zurück zum Login-Bereich</router-link> |
        <router-link to="/Formulare">Zurück zu Ihren Formularen</router-link>
      </div>
    </nav>

    <div class="mt-4" v-if="formData !== null"
      :style="{ 'pointer-events': pointerEvents ? 'initial' : 'none', 'opacity': pointerEvents || $store.state.oneTimeAccessUUID ? '1.0' : '0.5' }">
      <img src="/img/kienbaum.svg" class="logo-kienbaum-small mb-4 mx-auto hide-at-print" alt="" width="190">

      <h2 class="mb-3">{{ formData.FORM }}</h2>

      <div class="parent">
        <div class="grid-item"></div>
        <div class="grid-item fw-bold">Frühstück</div>
        <div class="grid-item fw-bold">Mittag</div>
        <div class="grid-item fw-bold">Abendessen</div>
        <div class="grid-item"></div>
        <div class="grid-item fw-bold">Snack</div>
        <div class="grid-item fw-bold">Getränke</div>
        <div class="grid-item fw-bold">Wünsche</div>
      </div>
      <div v-for="(item, index) in formData.KUECHE.normal" class="kueche-grid">
        <div class="div-datum">
          <div>{{ new
    Date(item.DATUM).toLocaleDateString('de',
      dateOptionsWeekdayOnly)
}}</div>
          <div>{{ new
    Date(item.DATUM).toLocaleDateString('de',
      dateOptions)
}}</div>
        </div>

        <div class="div-frueh-z w-100 h-100">
          <input type="time" class="w-100" v-model="item.FRUEH_Z" @change="firstFRUEHTimeChanged(index)" />
          <div v-if="index === 0 && showCopyFRUEHTime">
            <a href="#" @click.prevent="copyFRUEHTime(item.FRUEH_Z)">Zeit nach unten übertragen</a>
          </div>
        </div>
        <input class="div-frueh-p w-100 h-100" type="number" v-model.number="item.FRUEH_P" min="0" max="999" step="1"
          @blur="isInvalidNumberInput(item.FRUEH_P) ? item.FRUEH_P = 0 : ''" />
        <input class="div-frueh-t w-100 h-100" type="text" v-model="item.FRUEH_T" />

        <div class="div-mittag-z w-100 h-100">
          <input type="time" class="w-100" v-model="item.MITTAG_Z" @change="firstMITTAGTimeChanged(index)" />
          <div v-if="index === 0 && showCopyMITTAGTime">
            <a href="#" @click.prevent="copyMITTAGTime(item.MITTAG_Z)">Zeit nach unten übertragen</a>
          </div>
        </div>
        <input class="div-mittag-p w-100 h-100" type="number" v-model.number="item.MITTAG_P" min="0" max="999" step="1"
          @blur="isInvalidNumberInput(item.MITTAG_P) ? item.MITTAG_P = 0 : ''" />
        <input class="div-mittag-t w-100 h-100" type="text" v-model="item.MITTAG_T" />

        <div class="div-abend-z w-100 h-100">
          <input type="time" class="w-100" v-model="item.ABEND_Z" @change="firstABENDTimeChanged(index)" />
          <div v-if="index === 0 && showCopyABENDTime">
            <a href="#" @click.prevent="copyABENDTime(item.ABEND_Z)">Zeit nach unten übertragen</a>
          </div>
        </div>
        <input class="div-abend-p w-100 h-100" type="number" v-model.number="item.ABEND_P" min="0" max="999" step="1"
          @blur="isInvalidNumberInput(item.ABEND_P) ? item.ABEND_P = 0 : ''" />
        <input class="div-abend-t w-100 h-100" type="text" v-model="item.ABEND_T" />

        <input class="div-snack-z w-100 h-100" type="text" v-model="item.SNACK_Z" />
        <input class="div-snack-p w-100 h-100" type="number" v-model.number="item.SNACK_P" min="0" max="999" step="1"
          @blur="isInvalidNumberInput(item.SNACK_P) ? item.SNACK_P = 0 : ''" />
        <input class="div-snack-t w-100 h-100" type="text" v-model="item.SNACK_T" />

        <input class="div-wasser-z w-100 h-100" type="text" v-model="item.WASSER_Z" />
        <input class="div-wasser-p w-100 h-100" type="number" v-model.number="item.WASSER_P" min="0" max="999" step="1"
          @blur="isInvalidNumberInput(item.WASSER_P) ? item.WASSER_P = 0 : ''" />
        <input class="div-wasser-t w-100 h-100" type="text" v-model="item.WASSER_T" />

        <input class="div-sonst-z w-100 h-100" type="text" v-model="item.SONST_Z" />
        <input class="div-sonst-p w-100 h-100" type="number" v-model.number="item.SONST_P" min="0" max="999" step="1"
          @blur="isInvalidNumberInput(item.SONST_P) ? item.SONST_P = 0 : ''" />
        <input class="div-sonst-t w-100 h-100" type="text" v-model="item.SONST_T" />
      </div>
      <div class="parent">
        <div class="grid-item"></div>
        <div class="grid-item fw-bold py-2">{{ summeFRUEH }} Personen</div>
        <div class="grid-item fw-bold py-2">{{ summeMITTAG }} Personen</div>
        <div class="grid-item fw-bold py-2">{{ summeABEND }} Personen</div>
        <div class="grid-item"></div>
        <div class="grid-item"></div>
        <div class="grid-item"></div>
        <div class="grid-item"></div>
      </div>

      <h3 class="mt-5">Sonderverpflegung</h3>

      <div v-if="!$store.state.oneTimeAccessUUID" class="my-3 hide-at-print">
        <button type="button" class="btn btn-secondary btn-sm" @click="addSonderEntry()">Eintrag hinzufügen</button>
      </div>

      <div class="sonder-grid">
        <div class="grid-item div-sonder-personen fw-bold">Personen </div>
        <div class="grid-item div-sonder-art fw-bold">Art</div>
        <div class="grid-item div-sonder-info fw-bold">Info</div>
        <div class="grid-item"></div>
      </div>
      <div v-for="(item, index) in formData.KUECHE.sonder" class="sonder-grid">
        <div class="div-sonder-personen">
          <input class="div-sonst-p w-100" type="number" v-model.number="item.PERSONEN" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(item.PERSONEN) ? item.PERSONEN = 0 : ''" />
        </div>
        <div class="div-sonder-art">
          <input class="w-100" :list="'select' + index" :name="'select' + index" v-model="item.ART">
          <datalist class="" :id="'select' + index">
            <option v-for="entry in $store.state.sonderVerpflegungOptions" :value="entry" />
          </datalist>
        </div>
        <div class="div-sonder-info">
          <textarea rows="2" class="w-100 h-100" v-model="item.INFO"></textarea>
        </div>
        <div class="div-sonder-4" style="place-self: center;">
          <div class="w-100 h-100" style="cursor: pointer; font-size: 175%;" @click="removeSonderEntry(index)">
            &#128465;&#65039;
          </div>
        </div>
      </div>

      <div class="hide-at-print" v-if="!$store.state.oneTimeAccessUUID">
        <div class="mt-4">
          <button type="button" class="btn btn-secondary" @click="trySaveForm(true)">Zwischenspeichern</button>
          <div class="mt-2" :style="{
  'visibility': hasSaved ? 'visible' : 'hidden'
}"><small>Lokal gespeichert!</small></div>
        </div>

        <div>
          <div class="mt-3">
            <button type="button" class="btn btn-primary" @click="sendForm()">Küchenliste einreichen</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { registerAndGetPopups, checkOnlineStatus, getRealDateNow, getServerURL, isNullOrWhitespace, isInvalidNumberInput } from '@/helper';
import { set, get } from 'idb-keyval';

export default {
  name: 'KuechenlisteFormView',
  async mounted() {
    //Bei F5 reload wieder zu root, weil Passwort etc ja fehlt
    if (this.$store.state.bookingNumber === "" && !this.$store.state.oneTimeAccessUUID) {
      this.$router.push("/");
      return;
    }
    if (this.$store.state.oneTimeAccessUUID) {
      this.pointerEvents = false;
    }
    // die verschiedenen Popups registrieren
    this.toastList = registerAndGetPopups();

    const data = await get('lastEditedKuechenlisteForm');
    if (data) {
      this.formData = JSON.parse(data);
    } else {
      this.$router.push("Formulare");
    }
  },
  computed: {
    summeFRUEH() {
      let sum = 0;
      for (let index = 0; index < this.formData.KUECHE.normal.length; index++) {
        if (Number.isInteger(this.formData.KUECHE.normal[index].FRUEH_P)) {
          sum += this.formData.KUECHE.normal[index].FRUEH_P;
        }
      }
      return sum;
    },
    summeMITTAG() {
      let sum = 0;
      for (let index = 0; index < this.formData.KUECHE.normal.length; index++) {
        if (Number.isInteger(this.formData.KUECHE.normal[index].MITTAG_P)) {
          sum += this.formData.KUECHE.normal[index].MITTAG_P;
        }
      }
      return sum;
    },
    summeABEND() {
      let sum = 0;
      for (let index = 0; index < this.formData.KUECHE.normal.length; index++) {
        if (Number.isInteger(this.formData.KUECHE.normal[index].ABEND_P)) {
          sum += this.formData.KUECHE.normal[index].ABEND_P;
        }
      }
      return sum;
    },
  },
  methods: {
    async trySaveForm(showHint) {

      if (await checkOnlineStatus()) {

        const data = { bookingNumber: this.$store.state.bookingNumber };

        if (this.$store.state.institution) {
          data.password = this.formData.PASSWORT;
          data.isHashedPassword = true;
        } else {
          data.password = this.$store.state.password;
        }

        const response = await fetch(getServerURL() + '/api/forms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          return true; // ist ok hier
        }

        const serverFormlist = await response.json();
        const serverForm = serverFormlist.find(x => x.ID === this.formData.ID);

        if (!serverForm) {
          this.errorMessage = "Achtung - das Formular wurde zwischenzeitlich vom Administrator entfernt."
          this.toastList[0].show();

          setTimeout(() => {
            this.$router.push("/");
          }, 3000);
          return false;
        } else {
          if (serverForm.ZUGANG !== this.formData.ZUGANG) {
            this.errorMessage = "Achtung - das Formular wurde zwischenzeitlich vom Administrator geändert. Daten werden zurückgesetzt. Bitte erneut ausfüllen."
            this.toastList[0].show();

            this.formData = serverForm;

            this.saveForm();
            return false;
          }
        }
      }

      // Setze aktuelles Datum
      this.formData.USER_DATUM = getRealDateNow();

      if (showHint) {
        this.hasSaved = this.saveForm();

        if (this.hasSaved) {
          setTimeout(() => {
            this.hasSaved = false;
          }, 2000);
          return true;
        } else {
          return false;
        }
      } else {
        return this.saveForm();
      }
    },
    async saveForm() {
      try {

        await set("lastEditedKuechenlisteForm", JSON.stringify(this.formData));

        // Element auch aktualisieren in lokaler Liste
        const data = await get('localFormlist');
        let localFormlist = data ? JSON.parse(data) : [];

        localFormlist = localFormlist.map(x => {
          return this.formData.ID === x.ID ? this.formData : x;
        });

        await set('localFormlist', JSON.stringify(localFormlist));
        return true;
      } catch (error) {
        console.log('Saving error!', err);
        this.errorMessage = "Fehler beim Speichern des gewählten Formulars."
        this.toastList[0].show();
        return false;
      }
    },
    async sendForm() {

      if (!(await this.trySaveForm(false))) {
        return;
      }

      // Validieren
      for (let index = 0; index < this.formData.KUECHE.normal.length; index++) {
        const element = this.formData.KUECHE.normal[index];
        if (element.FRUEH_P > 0 && isNullOrWhitespace(element.FRUEH_Z)) {
          this.errorMessage = `Es fehlen notwendige Eingaben beim Formular. ${new
            Date(element.DATUM).toLocaleDateString('de', this.dateOptionsWeekdayOnly) + ' ' +
            new Date(element.DATUM).toLocaleDateString('de', this.dateOptions)
            } -> Frühstück -> Erste Zeile darf nicht leer sein, wenn Anzahl größer 0`
          this.toastList[0].show();
          return;
        }
        if (element.MITTAG_P > 0 && isNullOrWhitespace(element.MITTAG_Z)) {
          this.errorMessage = `Es fehlen notwendige Eingaben beim Formular. ${new
            Date(element.DATUM).toLocaleDateString('de', this.dateOptionsWeekdayOnly) + ' ' +
            new Date(element.DATUM).toLocaleDateString('de', this.dateOptions)
            } -> Mittag -> Erste Zeile darf nicht leer sein, wenn Anzahl größer 0`
          this.toastList[0].show();
          return;
        }
        if (element.ABEND_P > 0 && isNullOrWhitespace(element.ABEND_Z)) {
          this.errorMessage = `Es fehlen notwendige Eingaben beim Formular. ${new
            Date(element.DATUM).toLocaleDateString('de', this.dateOptionsWeekdayOnly) + ' ' +
            new Date(element.DATUM).toLocaleDateString('de', this.dateOptions)
            } -> Abendessen -> Erste Zeile darf nicht leer sein, wenn Anzahl größer 0`
          this.toastList[0].show();
          return;
        }
        if (element.SNACK_P > 0 && isNullOrWhitespace(element.SNACK_Z)) {
          this.errorMessage = `Es fehlen notwendige Eingaben beim Formular. ${new
            Date(element.DATUM).toLocaleDateString('de', this.dateOptionsWeekdayOnly) + ' ' +
            new Date(element.DATUM).toLocaleDateString('de', this.dateOptions)
            } -> Snack -> Erste Zeile darf nicht leer sein, wenn Anzahl größer 0`
          this.toastList[0].show();
          return;
        }
        if (element.WASSER_P > 0 && isNullOrWhitespace(element.WASSER_Z)) {
          this.errorMessage = `Es fehlen notwendige Eingaben beim Formular. ${new
            Date(element.DATUM).toLocaleDateString('de', this.dateOptionsWeekdayOnly) + ' ' +
            new Date(element.DATUM).toLocaleDateString('de', this.dateOptions)
            } -> Getränke -> Erste Zeile darf nicht leer sein, wenn Anzahl größer 0`
          this.toastList[0].show();
          return;
        }
        if (element.SONST_P > 0 && isNullOrWhitespace(element.SONST_Z)) {
          this.errorMessage = `Es fehlen notwendige Eingaben beim Formular. ${new
            Date(element.DATUM).toLocaleDateString('de', this.dateOptionsWeekdayOnly) + ' ' +
            new Date(element.DATUM).toLocaleDateString('de', this.dateOptions)
            } -> Wünsche -> Erste Zeile darf nicht leer sein, wenn Anzahl größer 0`
          this.toastList[0].show();
          return;
        }
      }

      for (let index = 0; index < this.formData.KUECHE.sonder.length; index++) {
        const element = this.formData.KUECHE.sonder[index];
        if (element.PERSONEN === 0) {
          this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Sonderverpflegung -> Anzahl Personen darf nicht 0 sein"
          this.toastList[0].show();
          return;
        }
        if (isNullOrWhitespace(element.ART)) {
          this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Sonderverpflegung -> Art"
          this.toastList[0].show();
          return;
        }
      }

      if (!(await checkOnlineStatus())) {
        this.errorMessage = "Das Formular kann erst übermittelt werden, wenn Sie wieder online sind. Ihre Eingaben bleiben bestehen, auch wenn der Browser geschlossen wird."
        this.toastList[0].show();
        return;
      }

      const data = { bookingNumber: this.$store.state.bookingNumber, formData: this.formData };

      if (this.$store.state.institution) {
        data.password = this.formData.PASSWORT;
        data.isHashedPassword = true;
      } else {
        data.password = this.$store.state.password;
      }

      fetch(getServerURL() + '/api/forms/' + this.formData.ID, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(async response => {
          if (!response.ok) {
            this.errorMessage = "Das Absenden war nicht möglich."
            this.toastList[0].show();
          } else {
            this.pointerEvents = false;
            this.toastList[1].show();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          this.errorMessage = "Das Absenden war nicht möglich."
          this.toastList[0].show();
        });
    },
    firstFRUEHTimeChanged(index) {
      if (index === 0) {
        this.showCopyFRUEHTime = true;
      }
    },
    copyFRUEHTime(time) {
      for (let index = 0; index < this.formData.KUECHE.normal.length; index++) {
        if (index !== 0) {
          const element = this.formData.KUECHE.normal[index];
          element.FRUEH_Z = time;
        }
      }
      this.showCopyFRUEHTime = false;
    },
    firstMITTAGTimeChanged(index) {
      if (index === 0) {
        this.showCopyMITTAGTime = true;
      }
    },
    copyMITTAGTime(time) {
      for (let index = 0; index < this.formData.KUECHE.normal.length; index++) {
        if (index !== 0) {
          const element = this.formData.KUECHE.normal[index];
          element.MITTAG_Z = time;
        }
      }
      this.showCopyMITTAGTime = false;
    },
    firstABENDTimeChanged(index) {
      if (index === 0) {
        this.showCopyABENDTime = true;
      }
    },
    copyABENDTime(time) {
      for (let index = 0; index < this.formData.KUECHE.normal.length; index++) {
        if (index !== 0) {
          const element = this.formData.KUECHE.normal[index];
          element.ABEND_Z = time;
        }
      }
      this.showCopyABENDTime = false;
    },
    addSonderEntry() {
      this.formData.KUECHE.sonder.push(
        {
          "ART": "",
          "PERSONEN": 0,
          "INFO": ""
        });
    },
    removeSonderEntry(index) {
      this.formData.KUECHE.sonder.splice(index, 1);
    },
    isInvalidNumberInput(val) {
      return isInvalidNumberInput(val);
    }
  },
  data() {
    return {
      toastList: [],
      pointerEvents: true,
      errorMessage: "",
      dateOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
      dateOptionsWeekdayOnly: { weekday: 'long' },

      formData: null,
      hasSaved: false,

      showCopyFRUEHTime: false,
      showCopyMITTAGTime: false,
      showCopyABENDTime: false,
    }
  },
}
</script>
<style scoped>
@media (max-width: 799px) {
  .logo-kienbaum-800 {
    display: none;
  }

  .logo-kienbaum-small {
    display: block;
  }
}

@media (min-width: 800px) {
  .logo-kienbaum-800 {
    display: block;
    position: absolute;
    right: 2rem;
    top: 3rem;
  }

  .logo-kienbaum-small {
    display: none;
  }
}

@media print {
  @page {
    size: A3;
    /* interessanter Workaround im das Fit-to-page auf A4 anzustoßen: https: //stackoverflow.com/questions/28757370/scale-html-table-before-printing-using-css */
  }

  .hide-at-print {
    display: none;
  }
}

input,
textarea,
select {
  background-color: #e9e9ed;
  padding-left: 0.5rem;
}

select {
  padding-left: 0.1rem;
}

h2 {
  text-decoration: underline;
}

.parent {
  min-width: 1000px;
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  /* minmax(0, 1fr) anstelle von 1fr siehe https: //stackoverflow.com/questions/60193734/grid-template-columns-in-chrome-80-inconsistently-computed */
  /*grid-template-rows: 3fr repeat(2, 1fr);*/
  /* ohne Angabe von grid-template-rows scheint es einfach auto-height zu sein */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid black;
}

.grid-item {
  border: 1px solid black;
}

.kueche-grid {
  min-width: 1000px;
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  /* minmax(0, 1fr) anstelle von 1fr siehe https: //stackoverflow.com/questions/60193734/grid-template-columns-in-chrome-80-inconsistently-computed */
  /* grid-template-rows: repeat(3, minmax(0, 1fr)); */
  /* ohne Angabe von grid-template-rows scheint es einfach auto-height zu sein */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid black;
}

.div-datum {
  grid-area: 1 / 1 / 4 / 2;
  place-self: center;
  justify-self: center;
}

.div-frueh-z {
  grid-area: 1 / 2 / 2 / 3;
}

.div-frueh-p {
  grid-area: 2 / 2 / 3 / 3;
}

.div-frueh-t {
  grid-area: 3 / 2 / 4 / 3;
}

.div-mittag-z {
  grid-area: 1 / 3 / 2 / 4;
}

.div-mittag-p {
  grid-area: 2 / 3 / 3 / 4;
}

.div-mittag-t {
  grid-area: 3 / 3 / 4 / 4;
}

.div-abend-z {
  grid-area: 1 / 4 / 2 / 5;
}

.div-abend-p {
  grid-area: 2 / 4 / 3 / 5;
}

.div-abend-t {
  grid-area: 3 / 4 / 4 / 5;
}

.div-snack-z {
  grid-area: 1 / 6 / 2 / 7;
}

.div-snack-p {
  grid-area: 2 / 6 / 3 / 7;
}

.div-snack-t {
  grid-area: 3 / 6 / 4 / 7;
}

.div-wasser-z {
  grid-area: 1 / 7 / 2 / 8;
}

.div-wasser-p {
  grid-area: 2 / 7 / 3 / 8;
}

.div-wasser-t {
  grid-area: 3 / 7 / 4 / 8;
}

.div-sonst-z {
  grid-area: 1 / 8 / 2 / 9;
}

.div-sonst-p {
  grid-area: 2 / 8 / 3 / 9;
}

.div-sonst-t {
  grid-area: 3 / 8 / 4 / 9;
}

.sonder-grid {
  min-width: 1000px;
  display: grid;
  grid-template-columns: 6rem 1fr 2fr 3rem;
  /* grid-template-rows: repeat(3, minmax(0, 1fr)); */
  /* ohne Angabe von grid-template-rows scheint es einfach auto-height zu sein */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid black;
}
</style>
