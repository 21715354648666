<template>
  <div class="">

    <!-- Popups-->
    <div class="mypopup position-fixed top-50 start-50 translate-middle">
      <div class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
        <div class="toast-header">
          <strong class="me-auto" style="color: coral;">Fehler</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <div class="mypopup position-fixed top-50 start-50 translate-middle">
      <div class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
        <div class="toast-header">
          <strong class="me-auto" style="color: lightgreen;">Erfolgreich übermittelt</strong>
          <router-link to="/Formulare">
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </router-link>
        </div>
        <div class="toast-body">
          Sie können nun keine Änderungen mehr vornehmen.
        </div>
      </div>
    </div>
    <!-- Ende Popups-->

    <nav class="hide-at-print">
      <div :style="{ 'visibility': !$store.state.oneTimeAccessUUID ? 'visible' : 'hidden' }">
        <router-link to="/">Zurück zum Login-Bereich</router-link> |
        <router-link to="/Formulare">Zurück zu Ihren Formularen</router-link>
      </div>
    </nav>

    <div class="mt-4" v-if="formData !== null"
      :style="{ 'pointer-events': pointerEvents ? 'initial' : 'none', 'opacity': pointerEvents || $store.state.oneTimeAccessUUID ? '1.0' : '0.5' }">


      <div class="row mb-3" style="align-items: center; justify-content: center;">
        <div class="col-auto">
          <div class="fw-bold">Kienbaum - Olympisches und Paralympisches Trainingszentrum für Deutschland e. V.
          </div>
          <div>Puschkinstraße 2, 15537 Grünheide / OT Kienbaum</div>
          <div>
            <span class="d-inline-block me-5">Tel: (033434) 76 - 0</span>
            <span class="d-inline-block me-5">Fax: (033434) 76 - 290</span>
            <span class="d-inline-block">E-Mail: office@kienbaum-sport.de</span>
          </div>
        </div>
        <div class="col-auto ps-3">
          <img src="/img/kienbaum.svg" class="logo-kienbaum-800" alt="" width="190">
        </div>
      </div>

      <div class="parent">
        <div class="item-header-center">
          <h3 class="mt-2">Vertrag Nr. <i>{{ formData.NUMMER }}</i></h3>
        </div>
        <div class="item-header-locationanddate">
          Kienbaum, {{
            new
              Date(currentDate).toLocaleDateString('de',
                dateOptions)
          }}
        </div>
        <div class="item-header-left">
          <h4 class="ms-3 mt-2">Ihr Lehrgang / Aufenthalt</h4>
        </div>

        <div class="item-buchung">
          <div class="fw-bold mt-2 ms-3 text-decoration-underline">Allgemeine Angaben</div>
          <div class="grid-buchung mt-2 mx-3 mb-2">
            <div class="grid-buchung-item-thema-header"> <span class="fw-bold">Thema:</span> </div>
            <div class="grid-buchung-item-thema ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.BUCHUNG.SPORTART" />
            </div>
            <div class="grid-buchung-item-partner-header"> <span class="fw-bold">Vertragspartner:</span> </div>
            <div class="grid-buchung-item-partner ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.BUCHUNG.KUNDE" />
            </div>
            <div class="grid-buchung-item-anschrift-header"> <span class="fw-bold">Anschrift:</span></div>
            <div class="grid-buchung-item-anschrift ms-2">
              <textarea rows="3" class="w-100 h-100" v-model="formData.BUCHUNG.ANSCHRIFT"></textarea>
            </div>
            <div class="grid-buchung-item-leiter-header w-100"> <span
                class="fw-bold text-decoration-underline">Lehrgangsleiter /
                Ansprechpartner:</span> </div>

            <div class="grid-buchung-item-leitername-header"><span class="fw-bold">Name:</span> </div>
            <div class="grid-buchung-item-leitername ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.BUCHUNG.L_NAME" />
            </div>
            <div class="grid-buchung-item-leitertelefon-header"><span class="fw-bold">Tel.:</span> </div>
            <div class="grid-buchung-item-leitertelefon ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.BUCHUNG.L_TELEFON" />
            </div>
            <div class="grid-buchung-item-leiterhandy-header"><span class="fw-bold">Handy:</span> </div>
            <div class="grid-buchung-item-leiterhandy ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.BUCHUNG.L_MOBIL" />
            </div>
            <div class="grid-buchung-item-leiteremail-header w-100"><span class="fw-bold">E-Mail:</span> </div>
            <div class="grid-buchung-item-leiteremail ms-2">
              <input class="w-100 h-100" type="email" v-model="formData.BUCHUNG.L_MAIL" />
            </div>
          </div>
        </div>
        <div class="item-rechnung fw-bold">
          <div class="fw-bold mt-2 ms-3 text-decoration-underline">Rechnungsanschrift</div>
          <div class="grid-rechnung mt-2 mx-3 mb-2">
            <div class="grid-rechnung-item-firma-header w-100"> <span class="fw-bold">Verein / Firma:</span> </div>
            <div class="grid-rechnung-item-firma ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.RECHNUNG.VEREIN" />
            </div>
            <div class="grid-rechnung-item-name-header"> <span class="fw-bold">Name:</span> </div>
            <div class="grid-rechnung-item-name ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.RECHNUNG.NAME" />
            </div>
            <div class="grid-rechnung-item-strasse-header"> <span class="fw-bold">Straße:</span></div>
            <div class="grid-rechnung-item-strasse ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.RECHNUNG.STRASSE" />
            </div>
            <div class="grid-rechnung-item-plz-header"> <span class="fw-bold">Postleitzahl:</span> </div>
            <div class="grid-rechnung-item-plz ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.RECHNUNG.PLZ" />
            </div>
            <div class="grid-rechnung-item-ort-header"><span class="fw-bold">Ort:</span> </div>
            <div class="grid-rechnung-item-ort ms-2">
              <input class="w-100 h-100" type="text" v-model="formData.RECHNUNG.ORT" />
            </div>
          </div>
          <div class="fw-normal w-100 mx-3 mt-3">Besteht der Wunsch zur geteilten Rechnungslegung, bitten wir
            um
            eine
            gesonderte
            Auflistung.</div>
        </div>


        <div class="item-termin-header mt-2 mx-3 mb-2 w-100">
          <span class="fw-bold text-decoration-underline">Zeitliche Angaben / Mahlzeiten</span>
        </div>
        <div class="item-terminvon-header ms-3"><span class="fw-bold">vom:</span> </div>
        <div class="item-terminvon ms-1">
          <input class="w-100 h-100" type="date" v-model="anreiseDate" />
        </div>
        <div class="item-terminvonuhrzeit-header w-100"><span class="fw-bold">Uhrzeit Anreise:</span> </div>
        <div class="item-terminvonuhrzeit me-3">
          <input class="w-100 h-100" type="time" v-model="anreiseTime" />
        </div>
        <div class="item-terminvonmahlzeit-header mt-2 w-100"><span class="fw-bold">Erste Mahlzeit:</span> </div>
        <div class="item-terminvonmahlzeit mt-2 me-3">
          <select class="w-100 h-100" v-model="formData.BUCHUNG.AN_ESSEN">
            <option v-for="item in $store.state.essenOptions" :value="item">{{ item }}</option>
          </select>
        </div>

        <div class="item-terminbis-header ms-3 mt-3"><span class="fw-bold">bis:</span> </div>
        <div class="item-terminbis ms-1 mt-3">
          <input class="w-100 h-100" type="date" v-model="abreiseDate" />
        </div>
        <div class="item-terminbisuhrzeit-header mt-3 w-100"><span class="fw-bold">Uhrzeit Abreise:</span> </div>
        <div class="item-terminbisuhrzeit me-3 mt-3">
          <input class="w-100 h-100" type="time" v-model="abreiseTime" />
        </div>
        <div class="item-terminbismahlzeit-header mt-3 w-100"><span class="fw-bold">Letzte Mahlzeit:</span> </div>
        <div class="item-terminbismahlzeit mt-3 me-3">
          <select class="w-100 h-100" v-model="formData.BUCHUNG.AB_ESSEN">
            <option v-for="item in $store.state.essenOptions" :value="item">{{ item }}</option>
          </select>
        </div>

        <div class="item-sonstiges-header mt-2 mx-3 mb-2">
          <span class="fw-bold text-decoration-underline">Sonstiges</span>
        </div>

        <div class="item-sonstiges-preisgruppe-header ms-3 my-2"><span class="fw-bold">Preisgruppe:</span> </div>
        <div class="item-sonstiges-preisgruppe ms-1 m-auto">
          <span class="w-100 h-auto" v-text="formData.BUCHUNG.PREISGRUPPE"></span>
        </div>

        <div class="item-sonstiges-zahlweise-header ms-3"><span class="fw-bold">Zahlweise:</span> </div>
        <div class="item-sonstiges-zahlweise ms-1">
          <select class="w-100 h-auto mt-2" v-model="formData.BUCHUNG.ZAHLWEISE">
            <option v-for="item in zahlweiseOptions" :value="item">{{ item }}</option>
          </select>
        </div>

        <div class="item-sonstiges-physio-header ms-3 w-100"><span class="fw-bold">Physiotherapie (für
            Bundeskader):</span>
        </div>
        <div class="item-sonstiges-physio ms-1">
          <select class="w-100 h-auto mt-2" v-model="formData.BUCHUNG.PHYSIO">
            <option v-for="item in $store.state.physioOptions" :value="item">{{ item }}</option>
          </select>
        </div>

        <div class="item-sonstiges-hinweis-anlagennutzung-header ms-3 mt-2 w-100"><span
            class="fw-bold">Sportanlagennutzung /
            Trainingszeiten:</span> </div>
        <div class="item-sonstiges-hinweis-anlagennutzung ms-1 text-normal mt-2">
          <span class="">
            Bitte einen zeitlichen Ablaufplan mit allen Anforderungen erstellen und als Anlage zum Vertrag zusenden.
          </span>
        </div>

        <div class="item-hinweise-buchung-header mt-2 mx-3 mb-2 w-100">
          <span class="fw-bold text-decoration-underline">Hinweise zur Buchung</span>
        </div>

        <div class="item-hinweise-buchung mt-2 mx-3 mb-2">
          <span class="">Die Buchung wird rechtskräftig innerhalb von 14 Tagen nach Absenden dieses Formulars.<br>
            Voraussetzung für die Nutzung der Sportanlagen ist die Übermittlung eines Ablaufplanes mit allen
            Anforderungen.
            <br>
            <b>Mit dem Absenden werden die Allgemeinen Geschäftsbedingungen inkl. der Haus-, Park-, Schwimmhallen- und
              Sportstättenordnung sowie eines gültigen Hygienekonzepts, die Datenschutzerklärung und die gültige
              Preisliste anerkannt.</b>
            <br>
            Der Vertragspartner haftet gegenüber dem KOPT für die Bezahlung der zum Stichtag bestellten Leistungen (lt.
            AGB).
          </span>
        </div>

        <div class="item-teilnehmer-header mt-2 mx-3 mb-2">
          <span class="fw-bold text-decoration-underline">Angaben zu den Teilnehmenden / Aufteilung</span>
        </div>

        <div class="item-teilnehmer-anzahl-header ms-3 w-100"><span class="fw-bold">Anzahl gesamt:</span> </div>
        <div class="item-teilnehmer-anzahl ms-1">
          <input class="w-100 h-100" type="number" v-model="formData.BUCHUNG.ANZAHL" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(formData.BUCHUNG.ANZAHL) ? formData.BUCHUNG.ANZAHL = 0 : ''" />
        </div>

        <div class="item-teilnehmer-athletinnen-header ms-3 mt-2"><span class="fw-bold">Athletinnen:</span> </div>
        <div class="item-teilnehmer-athletinnen ms-1 mt-2">
          <input class="w-100 h-100" type="number" v-model="formData.BUCHUNG.ATHLETEN_W" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(formData.BUCHUNG.ATHLETEN_W) ? formData.BUCHUNG.ATHLETEN_W = 0 : ''" />
        </div>
        <div class="item-teilnehmer-athleten-header mt-2"><span class="fw-bold">Athleten:</span> </div>
        <div class="item-teilnehmer-athleten ms-1 mt-2">
          <input class="w-100 h-100" type="number" v-model="formData.BUCHUNG.ATHLETEN_M" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(formData.BUCHUNG.ATHLETEN_M) ? formData.BUCHUNG.ATHLETEN_M = 0 : ''" />
        </div>

        <div class="item-teilnehmer-weitere-wbl-header ms-3 mt-2"><span class="fw-bold">Trainerinnen/Betreu.:</span>
        </div>
        <div class="item-teilnehmer-weitere-wbl ms-1 mt-2">
          <input class="w-100 h-auto" type="number" v-model="formData.BUCHUNG.TRAINER_W" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(formData.BUCHUNG.TRAINER_W) ? formData.BUCHUNG.TRAINER_W = 0 : ''" />
        </div>
        <div class="item-teilnehmer-weitere-ml-header mt-2"><span class="fw-bold">Trainer/Betreuer:</span> </div>
        <div class="item-teilnehmer-weitere-ml ms-1 mt-2">
          <input class="w-100 h-auto" type="number" v-model="formData.BUCHUNG.TRAINER_M" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(formData.BUCHUNG.TRAINER_M) ? formData.BUCHUNG.TRAINER_M = 0 : ''" />
        </div>

        <div class="item-teilnehmer-einzelzimmer-header mt-2 ms-1 w-100"><span class="fw-bold">Gewünschte Anzahl
            Einzelzimmer:</span> </div>
        <div class="item-teilnehmer-einzelzimmer ms-1 mt-2">
          <input class="w-100 h-auto" type="number" v-model="formData.BUCHUNG.EINZELZIM" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(formData.BUCHUNG.EINZELZIM) ? formData.BUCHUNG.EINZELZIM = 0 : ''" />
        </div>

        <div class="item-unterbringung-header mt-2 mx-3 mb-2 w-100">
          <span class="fw-bold text-decoration-underline">Unterbringung (Wunsch)</span>
        </div>

        <div class="item-unterbringungkb1-header ms-3 w-100"><span class="fw-bold">Kienbaum 1:</span>
        </div>
        <div class="item-unterbringungkb1 ms-1 m-auto">
          <input type="checkbox" v-model="formData.BUCHUNG.KIENBAUM_1" />
        </div>
        <div class="item-unterbringungkb2-header w-100"><span class="fw-bold">Kienbaum 2:</span> </div>
        <div class="item-unterbringungkb2 ms-1 m-auto">
          <input type="checkbox" v-model="formData.BUCHUNG.KIENBAUM_2" />
        </div>

        <div class="item-unterbringung-bemerkung-header ms-3 my-3"><span class="fw-bold">Bemerkung:</span> </div>
        <div class="item-unterbringung-bemerkung ms-1 my-3">
          <textarea id="bemerkungTextArea" class="w-100" v-model="formData.BUCHUNG.BEMERKUNG"
            @input="setTextAreaHeight()"></textarea>
        </div>

        <div class="item-unterbringungkb1-rolli-header ms-3"><span class="fw-bold">Rollstuhlfahrer:</span>
        </div>
        <div class="item-unterbringungkb1-rolli ms-1">
          <input class="w-100 h-auto" type="number" v-model="formData.BUCHUNG.ROLLI_1" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(formData.BUCHUNG.ROLLI_1) ? formData.BUCHUNG.ROLLI_1 = 0 : ''" />
        </div>

        <div class="item-verteilung-header mt-2 mx-3 mb-2 w-100">
          <span class="fw-bold text-decoration-underline">Anzahl und Verteilung Sportler</span>
        </div>

        <div class="grid-item-verteilung item-verteilung-alter-header ms-3 text-center">
          <span class="">Alter / Kaderstatus</span>
        </div>
        <div class="grid-item-verteilung item-verteilung-alter-3-6-header text-center">
          <span class="">3-6 Jahre</span>
        </div>
        <div class="grid-item-verteilung item-verteilung-alter-7-12-header text-center">
          <span class="">7-12 Jahre</span>
        </div>
        <div class="grid-item-verteilung item-verteilung-alter-13-header text-center">
          <span class="">ab 13 Jahre</span>
        </div>
        <div class="grid-item-verteilung item-verteilung-spitzensport-header text-center">
          <span class="">Spitzensport <br>OK PK EK NK1 NK2</span>
        </div>
        <div class="grid-item-verteilung item-verteilung-landeskader-header text-center">
          <span class="">Landeskader <br>NK3</span>
        </div>

        <div class="grid-item-verteilung item-verteilung-anzahl-header ms-3 text-center" style="line-height:2rem">
          <span class="">Anzahl</span>
        </div>
        <div class="grid-item-verteilung item-verteilung-alter-3-6">
          <input class="w-100 h-100" type="number" v-model="formData.BUCHUNG.GAST3_6" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(formData.BUCHUNG.GAST3_6) ? formData.BUCHUNG.GAST3_6 = 0 : ''" />
        </div>
        <div class="grid-item-verteilung item-verteilung-alter-7-12">
          <input class="w-100 h-100" type="number" v-model="formData.BUCHUNG.GAST7_12" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(formData.BUCHUNG.GAST7_12) ? formData.BUCHUNG.GAST7_12 = 0 : ''" />
        </div>
        <div class="grid-item-verteilung item-verteilung-alter-13">
          <input class="w-100 h-100" type="number" v-model="formData.BUCHUNG.GASTERW" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(formData.BUCHUNG.GASTERW) ? formData.BUCHUNG.GASTERW = 0 : ''" />
        </div>
        <div class="grid-item-verteilung item-verteilung-spitzensport">
          <input class="w-100 h-100" type="number" v-model="formData.BUCHUNG.SPITZENSPORT" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(formData.BUCHUNG.SPITZENSPORT) ? formData.BUCHUNG.SPITZENSPORT = 0 : ''" />
        </div>
        <div class="grid-item-verteilung item-verteilung-landeskader">
          <input class="w-100 h-100" type="number" v-model="formData.BUCHUNG.LANDESKADER" min="0" max="999" step="1"
            @blur="isInvalidNumberInput(formData.BUCHUNG.LANDESKADER) ? formData.BUCHUNG.LANDESKADER = 0 : ''" />
        </div>

      </div>

      <div class="hide-at-print" v-if="!$store.state.oneTimeAccessUUID && !formData.BUCHUNG.ABGESENDET">
        <div class="mt-4">
          <button type="button" class="btn btn-secondary" @click="trySaveForm(true)">Zwischenspeichern</button>
          <div class="mt-2" :style="{
            'visibility': hasSaved ? 'visible' : 'hidden'
          }"><small>Lokal gespeichert!</small></div>
        </div>

        <div>
          <div class="mt-3">
            <button type="button" class="btn btn-primary" @click="sendForm()">Vertrag einreichen</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { registerAndGetPopups, checkOnlineStatus, getRealDateNow, getServerURL, isInvalidNumberInput } from '@/helper';
import { set, get } from 'idb-keyval';
import "@/assets/grid-rechnung.css";

export default {
  name: 'VertragFormView',
  async mounted() {
    //Bei F5 reload wieder zu root, weil Passwort etc ja fehlt
    if (this.$store.state.bookingNumber === "" && !this.$store.state.oneTimeAccessUUID) {
      this.$router.push("/");
      return;
    }

    // die verschiedenen Popups registrieren
    this.toastList = registerAndGetPopups();

    const data = await get('lastEditedVertragForm');
    if (data) {

      let dataParsed = JSON.parse(data);
      if (dataParsed.BUCHUNG.ABGESENDET === undefined) {
        dataParsed.BUCHUNG.ABGESENDET = "";
      }
      this.formData = dataParsed;

      if (this.formData.BUCHUNG.ANREISE.indexOf(" ") !== -1) {
        this.anreiseDate = this.formData.BUCHUNG.ANREISE.split(" ")[0];
        this.anreiseTime = this.formData.BUCHUNG.ANREISE.split(" ")[1];
      }

      if (this.formData.BUCHUNG.ABREISE.indexOf(" ") !== -1) {
        this.abreiseDate = this.formData.BUCHUNG.ABREISE.split(" ")[0];
        this.abreiseTime = this.formData.BUCHUNG.ABREISE.split(" ")[1];
      }

      if (this.$store.state.oneTimeAccessUUID || this.formData.BUCHUNG.ABGESENDET) {
        this.pointerEvents = false;
        if (this.formData.BUCHUNG.ABGESENDET) {
          this.currentDate = new Date(this.formData.BUCHUNG.ABGESENDET);
        } else {
          this.currentDate = new Date();
        }
      } else {
        this.currentDate = new Date();
      }
      setTimeout(() => this.setTextAreaHeight(), 250);
    } else {
      this.$router.push("Formulare");
    }
  },
  watch: {
  },
  methods: {
    async trySaveForm(showHint) {

      if (await checkOnlineStatus()) {

        const data = { bookingNumber: this.$store.state.bookingNumber };

        if (this.$store.state.institution) {
          data.password = this.formData.PASSWORT;
          data.isHashedPassword = true;
        } else {
          data.password = this.$store.state.password;
        }

        const response = await fetch(getServerURL() + '/api/forms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          return true; // ist ok hier
        }

        const serverFormlist = await response.json();
        const serverForm = serverFormlist.find(x => x.ID === this.formData.ID);

        if (!serverForm) {
          this.errorMessage = "Achtung - das Formular wurde zwischenzeitlich vom Administrator entfernt."
          this.toastList[0].show();

          setTimeout(() => {
            this.$router.push("/");
          }, 3000);
          return false;
        } else {
          if (serverForm.ZUGANG !== this.formData.ZUGANG) {
            this.errorMessage = "Achtung - das Formular wurde zwischenzeitlich vom Administrator geändert. Daten werden zurückgesetzt. Bitte erneut ausfüllen."
            this.toastList[0].show();

            this.formData = serverForm;

            this.saveForm();
            return false;
          }
        }
      }

      // Setze aktuelles Datum
      this.formData.USER_DATUM = getRealDateNow();
      // Setze schonmal Anreise und Abreise Daten, damit sie erstmal lokal gespeichert werden
      this.formData.BUCHUNG.ANREISE = this.anreiseDate + " " + this.anreiseTime;
      this.formData.BUCHUNG.ABREISE = this.abreiseDate + " " + this.abreiseTime;

      if (showHint) {
        this.hasSaved = this.saveForm();

        if (this.hasSaved) {
          setTimeout(() => {
            this.hasSaved = false;
          }, 2000);
          return true;
        } else {
          return false;
        }
      } else {
        return this.saveForm();
      }
    },
    async saveForm() {
      try {

        await set("lastEditedVertragForm", JSON.stringify(this.formData));

        // Element auch aktualisieren in lokaler Liste
        const data = await get('localFormlist');
        let localFormlist = data ? JSON.parse(data) : [];

        localFormlist = localFormlist.map(x => {
          return this.formData.ID === x.ID ? this.formData : x;
        });

        await set('localFormlist', JSON.stringify(localFormlist));
        return true;
      } catch (error) {
        console.log('Saving error!', err);
        this.errorMessage = "Fehler beim Speichern des gewählten Formulars."
        this.toastList[0].show();
        return false;
      }
    },
    async sendForm() {

      if (!(await checkOnlineStatus())) {
        this.errorMessage = "Das Formular kann erst übermittelt werden, wenn Sie wieder online sind. Ihre Eingaben bleiben bestehen, auch wenn der Browser geschlossen wird."
        this.toastList[0].show();
        return;
      }

      this.formData.BUCHUNG.ABGESENDET = getRealDateNow();

      if (!(await this.trySaveForm(false))) {
        this.formData.BUCHUNG.ABGESENDET = "";
        return;
      }

      // Validieren
      // if (!this.formData.RECHNUNG.VEREIN) {
      //   this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Rechnungsanschrift -> Verein / Firma"
      //   this.toastList[0].show();
      //  this.formData.BUCHUNG.ABGESENDET = "";
      //   return;
      // }
      // if (!this.formData.RECHNUNG.NAME) {
      //   this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Rechnungsanschrift -> Name"
      //   this.toastList[0].show();
      // this.formData.BUCHUNG.ABGESENDET = "";
      //   return;
      // }
      // if (!this.formData.RECHNUNG.STRASSE) {
      //   this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Rechnungsanschrift -> Straße"
      //   this.toastList[0].show();
      // this.formData.BUCHUNG.ABGESENDET = "";
      //   return;
      // }
      // if (!this.formData.RECHNUNG.PLZ) {
      //   this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Rechnungsanschrift -> PLZ"
      //   this.toastList[0].show();
      //  this.formData.BUCHUNG.ABGESENDET = "";
      //   return;
      // }
      // if (!this.formData.RECHNUNG.ORT) {
      //   this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Rechnungsanschrift -> Ort"
      //   this.toastList[0].show();
      // this.formData.BUCHUNG.ABGESENDET = "";
      //   return;
      // }

      if (!this.formData.BUCHUNG.AN_ESSEN) {
        this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Erste Mahlzeit"
        this.toastList[0].show();
        this.formData.BUCHUNG.ABGESENDET = "";
        return;
      }

      if (!this.formData.BUCHUNG.AB_ESSEN) {
        this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Letzte Mahlzeit"
        this.toastList[0].show();
        this.formData.BUCHUNG.ABGESENDET = "";
        return;
      }

      if (!this.anreiseDate) {
        this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Anreisedatum"
        this.toastList[0].show();
        this.formData.BUCHUNG.ABGESENDET = "";
        return;
      }
      if (!this.anreiseTime) {
        this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Anreiseuhrzeit"
        this.toastList[0].show();
        this.formData.BUCHUNG.ABGESENDET = "";
        return;
      }

      if (!this.abreiseDate) {
        this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Abreisedatum"
        this.toastList[0].show();
        this.formData.BUCHUNG.ABGESENDET = "";
        return;
      }
      if (!this.abreiseTime) {
        this.errorMessage = "Es fehlen notwendige Eingaben beim Formular. Abreiseuhrzeit"
        this.toastList[0].show();
        this.formData.BUCHUNG.ABGESENDET = "";
        return;
      }

      if (new Date(this.formData.BUCHUNG.ABREISE) < new Date(this.formData.BUCHUNG.ANREISE)) {
        this.errorMessage = "Fehlerhafte Eingabe beim Formular. Die Abreise kann nicht vor der Anreise sein."
        this.toastList[0].show();
        this.formData.BUCHUNG.ABGESENDET = "";
        return;
      }

      const data = { bookingNumber: this.$store.state.bookingNumber, formData: this.formData };

      if (this.$store.state.institution) {
        data.password = this.formData.PASSWORT;
        data.isHashedPassword = true;
      } else {
        data.password = this.$store.state.password;
      }

      fetch(getServerURL() + '/api/forms/' + this.formData.ID, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(async response => {
          if (!response.ok) {
            this.errorMessage = "Das Absenden war nicht möglich."
            this.toastList[0].show();
            this.formData.BUCHUNG.ABGESENDET = "";
          } else {
            this.pointerEvents = false;
            this.toastList[1].show();
          }
        })
        .catch((error) => {
          this.formData.BUCHUNG.ABGESENDET = "";
          console.error('Error:', error);
          this.errorMessage = "Das Absenden war nicht möglich."
          this.toastList[0].show();
        });
    },
    isInvalidNumberInput(val) {
      return isInvalidNumberInput(val);
    },
    setTextAreaHeight() {
      const textArea = document.getElementById("bemerkungTextArea");
      textArea.style.height = "";
      textArea.style.height = textArea.scrollHeight + "px";
    }
  },
  data() {
    return {
      toastList: [],
      pointerEvents: true,
      errorMessage: "",
      dateOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },

      formData: null,
      hasSaved: false,

      zahlweiseOptions: ["Vor Ort", "Überweisung", "Split", ""],

      currentDate: null,
      anreiseDate: "",
      anreiseTime: "",

      abreiseDate: "",
      abreiseTime: ""
    }
  },
}
</script>
<style scoped>
@media (max-width: 799px) {
  .logo-kienbaum-800 {
    margin-top: 1.2rem;
  }
}

@media (min-width: 800px) {}

@media print {
  @page {
    size: A3;
    /* interessanter Workaround im das Fit-to-page auf A4 anzustoßen: https: //stackoverflow.com/questions/28757370/scale-html-table-before-printing-using-css */
  }

  .hide-at-print {
    display: none;
  }
}

input,
textarea,
select {
  background-color: #e9e9ed;
  padding-left: 0.5rem;
}

select {
  padding-left: 0.1rem;
}

h2 {
  text-decoration: underline;
}

h5 {
  font-weight: bold;
}

.parent {
  text-align: initial;
  min-width: 1450px;
  display: grid;
  grid-template-columns: repeat(20, minmax(0, 1fr));
  /* minmax(0, 1fr) anstelle von 1fr siehe https: //stackoverflow.com/questions/60193734/grid-template-columns-in-chrome-80-inconsistently-computed */
  /*grid-template-rows: 3fr repeat(2, 1fr);*/
  /* ohne Angabe von grid-template-rows scheint es einfach auto-height zu sein */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid black;

  padding: 1rem;
}

.item-header-center {
  grid-area: 1 / 1 / 2 / 21;
  text-align: center;
  padding-bottom: 1rem;
}

.item-header-locationanddate {
  grid-area: 1 / 1 / 2 / 21;
  text-align: right;
  padding-top: 0.4rem;
}

.item-header-left {
  grid-area: 2 / 1 / 3 / 21;
  text-align: left;
  /* padding-bottom: 2rem; */
}

.item-buchung {
  grid-area: 3 / 1 / 4 / 11;
}

.item-rechnung {
  grid-area: 3 / 11 / 4 / 21;
}

.grid-buchung {
  display: grid;
  grid-template-columns: 2fr 5fr;
  /* grid-template-rows: repeat(8, 1fr); -> lieber auto height*/
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.grid-buchung-item-thema-header {
  grid-area: 1 / 1 / 2 / 2;
  place-self: center;
  justify-self: start;
}

.grid-buchung-item-thema {
  grid-area: 1 / 2 / 2 / 3;
}

.grid-buchung-item-partner-header {
  grid-area: 2 / 1 / 3 / 2;
  place-self: center;
  justify-self: start;
}

.grid-buchung-item-partner {
  grid-area: 2 / 2 / 3 / 3;
}

.grid-buchung-item-anschrift-header {
  grid-area: 3 / 1 / 4 / 2;
  place-self: center;
  justify-self: start;
}

.grid-buchung-item-anschrift {
  grid-area: 3 / 2 / 4 / 3;
}

.grid-buchung-item-leiter-header {
  grid-area: 4 / 1 / 5 / 11;
  place-self: center;
  justify-self: start;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
}

.grid-buchung-item-leitername-header {
  grid-area: 5 / 1 / 6 / 2;
  place-self: center;
  justify-self: start;
}

.grid-buchung-item-leitername {
  grid-area: 5 / 2 / 6 / 3;
}

.grid-buchung-item-leitertelefon-header {
  grid-area: 6 / 1 / 7 / 2;
  place-self: center;
  justify-self: start;
}

.grid-buchung-item-leitertelefon {
  grid-area: 6 / 2 / 7 / 3;
}

.grid-buchung-item-leiterhandy-header {
  grid-area: 7 / 1 / 8 / 2;
  place-self: center;
  justify-self: start;
}

.grid-buchung-item-leiterhandy {
  grid-area: 7 / 2 / 8 / 3;
}

.grid-buchung-item-leiteremail-header {
  grid-area: 8 / 1 / 9 / 2;
  place-self: center;
  justify-self: start;
}

.grid-buchung-item-leiteremail {
  grid-area: 8 / 2 / 9 / 3;
}

.item-termin-header {
  grid-area: 4 / 1 / 5 / 11;
  text-align: left;
}

.item-terminvon-header {
  grid-area: 5 / 1 / 6 / 4;
  place-self: center;
  justify-self: start;
}

.item-terminvon {
  grid-area: 5 / 4 / 6 / 6;
}

.item-terminvonuhrzeit-header {
  grid-area: 5 / 7 / 6 / 9;
  place-self: center;
  justify-self: start;
}

.item-terminvonuhrzeit {
  grid-area: 5 / 9 / 6 / 11;
}

.item-terminvonmahlzeit-header {
  grid-area: 6 / 7 / 7 / 9;
  place-self: center;
  justify-self: start;
}

.item-terminvonmahlzeit {
  grid-area: 6 / 9 / 7 / 11;
}




.item-terminbis-header {
  grid-area: 7 / 1 / 8 / 4;
  place-self: center;
  justify-self: start;
}

.item-terminbis {
  grid-area: 7 / 4 / 8 / 6;
}

.item-terminbisuhrzeit-header {
  grid-area: 7 / 7 / 8 / 9;
  place-self: center;
  justify-self: start;
}

.item-terminbisuhrzeit {
  grid-area: 7 / 9 / 8 / 11;
}

.item-terminbismahlzeit-header {
  grid-area: 8 / 7 / 9 / 9;
  place-self: center;
  justify-self: start;
}

.item-terminbismahlzeit {
  grid-area: 8 / 9 / 9 / 11;
}


.item-sonstiges-header {
  grid-area: 9 / 1 / 10 / 11;
  text-align: left;
}

.item-sonstiges-preisgruppe-header {
  grid-area: 10 / 1 / 11 / 6;
  place-self: center;
  justify-self: start;
}

.item-sonstiges-preisgruppe {
  grid-area: 10 / 6 / 11 / 10;
}

.item-sonstiges-zahlweise-header {
  grid-area: 11 / 1 / 12 / 6;
  place-self: center;
  justify-self: start;
}

.item-sonstiges-zahlweise {
  grid-area: 11 / 6 / 12 / 9;
}

.item-sonstiges-physio-header {
  grid-area: 12 / 1 / 13 / 6;
  place-self: center;
  justify-self: start;
}

.item-sonstiges-physio {
  grid-area: 12 / 6 / 13 / 8;
}

.item-sonstiges-hinweis-anlagennutzung-header {
  grid-area: 13 / 1 / 14 / 6;
  place-self: center;
  justify-self: start;
}

.item-sonstiges-hinweis-anlagennutzung {
  grid-area: 13 / 6 / 15 / 11;
}

.item-hinweise-buchung-header {
  grid-area: 17 / 1 / 18 / 11;
  text-align: left;
}

.item-hinweise-buchung {
  grid-area: 18 / 1 / 19 / 21;
  text-align: left;
}

.item-teilnehmer-header {
  grid-area: 4 / 11 / 5 / 21;
  text-align: left;
}

.item-teilnehmer-anzahl-header {
  grid-area: 5 / 11 / 6 / 14;
  place-self: center;
  justify-self: start;
}

.item-teilnehmer-anzahl {
  grid-area: 5 / 14 / 6 / 16;
}

.item-teilnehmer-athletinnen-header {
  grid-area: 6 / 11 / 7 / 14;
  place-self: center;
  justify-self: start;
}

.item-teilnehmer-athletinnen {
  grid-area: 6 / 14 / 7 / 16;
}

.item-teilnehmer-athleten-header {
  grid-area: 6 / 17 / 7 / 19;
  place-self: center;
  justify-self: start;
}

.item-teilnehmer-athleten {
  grid-area: 6 / 19 / 7 / 21;
}

.item-teilnehmer-weitere-wbl-header {
  grid-area: 7 / 11 / 8 / 14;
  place-self: center;
  justify-self: start;
}

.item-teilnehmer-weitere-wbl {
  grid-area: 7 / 14 / 8 / 16;
}

.item-teilnehmer-weitere-ml-header {
  grid-area: 7 / 17 / 8 / 19;
  place-self: center;
  justify-self: start;
}

.item-teilnehmer-weitere-ml {
  grid-area: 7 / 19 / 8 / 21;
}

.item-teilnehmer-einzelzimmer-header {
  grid-area: 8 / 14 / 9 / 19;
  place-self: center;
  justify-self: start;
}

.item-teilnehmer-einzelzimmer {
  grid-area: 8 / 19 / 9 / 21;
}

.item-unterbringung-header {
  grid-area: 9 / 11 / 10 / 21;
  text-align: left;
}

.item-unterbringungkb1-header {
  grid-area: 10 / 11 / 11 / 14;
  place-self: center;
  justify-self: start;
}

.item-unterbringungkb1 {
  grid-area: 10 / 14 / 11 / 16;
}

.item-unterbringungkb2-header {
  grid-area: 10 / 17 / 11 / 19;
  place-self: center;
  justify-self: start;
}

.item-unterbringungkb2 {
  grid-area: 10 / 19 / 11 / 21;
}

.item-unterbringung-bemerkung-header {
  grid-area: 16 / 1 / 17 / 2;
  place-self: center;
  justify-self: start;
}

.item-unterbringung-bemerkung {
  grid-area: 16 / 3 / 17 / 21;
}

.item-unterbringungkb1-rolli-header {
  grid-area: 11 / 11 / 12 / 14;
  place-self: center;
  justify-self: start;
}

.item-unterbringungkb1-rolli {
  grid-area: 11 / 14 / 12 / 16;
}

.item-verteilung-header {
  grid-area: 12 / 11 / 13 / 21;
  text-align: left;
}

.grid-item-verteilung {
  border: 1px solid black;
  font-size: 90%;
}

.item-verteilung-alter-header {
  grid-area: 13 / 11 / 14 / 13;
}

.item-verteilung-alter-3-6-header {
  grid-area: 13 / 13 / 14 / 14;
}

.item-verteilung-alter-7-12-header {
  grid-area: 13 / 14 / 14 / 16;
}

.item-verteilung-alter-13-header {
  grid-area: 13 / 16 / 14 / 17;
}

.item-verteilung-spitzensport-header {
  grid-area: 13 / 17 / 14 / 19;
}

.item-verteilung-landeskader-header {
  grid-area: 13 / 19 / 14 / 21;
}

.item-verteilung-anzahl-header {
  grid-area: 14 / 11 / 15 / 13;
}

.item-verteilung-alter-3-6 {
  grid-area: 14 / 13 / 15 / 14;
}

.item-verteilung-alter-7-12 {
  grid-area: 14 / 14 / 15 / 16;
}

.item-verteilung-alter-13 {
  grid-area: 14 / 16 / 15 / 17;
}

.item-verteilung-spitzensport {
  grid-area: 14 / 17 / 15 / 19;
}

.item-verteilung-landeskader {
  grid-area: 14 / 19 / 15 / 21;
}
</style>
